import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Alert,
  Share,
  Platform,
  ImageBackground,
  Modal,
  TextInput,
  TouchableWithoutFeedback,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import { SHARE_BASE_URL } from "../docs/config.js";
import { useNavigation } from "@react-navigation/native";
import Pro from "./Pro.js";
import Publi from "../components/Publi.js";
import * as Haptic from "expo-haptics";
import NeedRegister from "../selectables/NeedRegister.js";

const obtenerNombreProximoMes = (language) => {
  const meses = {
    ESP: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    CAT: [
      "Gener",
      "Febrer",
      "Març",
      "Abril",
      "Maig",
      "Juny",
      "Juliol",
      "Agost",
      "Setembre",
      "Octubre",
      "Novembre",
      "Desembre",
    ],
    ENG: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  const fechaActual = new Date();
  const proximoMesIndex = (fechaActual.getMonth() + 1) % 12;

  return meses[language][proximoMesIndex];
};

const translations = {
  ESP: {
    lookAtThisProfile: "Mira este perfil de Trocalia!",
    lookAtThisProfile2:
      "Te paso mi perfil en Trocalia, me iría genial que me dejases una valoración.",
    valorations: "Valoraciones",
    valorate: "Valorar",
    loading: "Cargando...",
    rateThisUser: "Valora a este usuario",
    leaveAComment: "Deja un comentario...",
    sendRating: "Enviar valoración",
    shareProfile: "Compartir URL del perfil",
    shareError: "No se pudo compartir. Inténtalo de nuevo.",
    shareNotAvailable:
      "La funcionalidad de compartir no está disponible en este navegador.",
    tituloLimiteAlcanzado:
      "¡Ya has abierto tu límite de chats nuevos este mes!",
    limiteAlcanzado: `¡Buenas noticias! Aún puedes continuar tus conversaciones actuales y recibir mensajes. Además, el día 1 de ${obtenerNombreProximoMes(
      "ESP"
    )} tu límite se reiniciará automáticamente y podrás abrir nuevos chats. ¿Quieres eliminar los límites y conectar con más usuarios desde ya? ¡Actualízate a PRO y pruébalo gratis para disfrutar de chats ilimitados con todos los usuarios que desees, sin esperas!`,
    cerrar: "Cerrar",
    verPro: "Prueba gratis PRO",
    chat: "Chat",
    noSelfRatingTitle: "No puedes valorarte a ti mismo!",
    noSelfRatingBody:
      "Pero puedes pedir a conocidos que te valoren y así mejorar tu perfil.",
    askForRating: "Pedir valoración",
  },
  CAT: {
    lookAtThisProfile: "Mira aquest perfil de Trocalia!",
    lookAtThisProfile2:
      "Et passo el meu perfil a Trocalia, m'aniria genial que em deixessis una valoració.",
    valorations: "Valoracions",
    valorate: "Valorar",
    loading: "Carregant...",
    rateThisUser: "Valora aquest usuari",
    leaveAComment: "Deixa un comentari...",
    sendRating: "Enviar valoració",
    shareProfile: "Compartir URL del perfil",
    shareError: "No es va poder compartir. Torna-ho a intentar.",
    shareNotAvailable:
      "La funcionalitat de compartir no està disponible en aquest navegador.",
    tituloLimiteAlcanzado: "Ja has obert el teu límit de xats nous aquest mes!",
    limiteAlcanzado: `Bones notícies! Encara pots continuar les teves converses actuals i rebre missatges. A més, l'1 de ${obtenerNombreProximoMes(
      "CAT"
    )} el teu límit es reiniciarà automàticament i podràs obrir nous xats. Vols eliminar els límits i connectar amb més usuaris ara mateix? Actualitza't a PRO i prova-ho gratis per gaudir de xats il·limitats amb tots els usuaris que vulguis, sense esperes!`,
    cerrar: "Tancar",
    verPro: "Prova gratis PRO",
    chat: "Xat",
    noSelfRatingTitle: "No et pots valorar a tu mateix!",
    noSelfRatingBody:
      "Però pots demanar a coneguts que et valorin i així millorar el teu perfil.",
    askForRating: "Demanar valoració",
  },
  ENG: {
    lookAtThisProfile: "Look at this Trocalia profile!",
    lookAtThisProfile2:
      "Here is my profile on Trocalia, I would really appreciate it if you could give me a rating.",
    valorations: "Valorations",
    valorate: "Valorate",
    loading: "Loading...",
    rateThisUser: "Rate this user",
    leaveAComment: "Leave a comment...",
    sendRating: "Send rating",
    shareProfile: "Share profile URL",
    shareError: "Couldn't share. Try again.",
    shareNotAvailable: "Share functionality is not available in this browser.",
    tituloLimiteAlcanzado: "You've reached your limit of new chats this month!",
    tituloLimiteAlcanzado:
      "You’ve already opened your limit of new chats this month!",
    limiteAlcanzado: `Good news! You can still continue your current conversations and receive messages. Plus, on the 1st of ${obtenerNombreProximoMes(
      "ENG"
    )} your chat limit will reset automatically, and you’ll be able to open new chats. Want to remove the limits and connect with more users right away? Upgrade to PRO and try it for free to enjoy unlimited chats with all the users you want, no waiting!`,
    cerrar: "Close",
    verPro: "Try PRO for free",
    chat: "Chat",
    noSelfRatingTitle: "You can't rate yourself!",
    noSelfRatingBody:
      "But you can ask acquaintances to rate you and improve your profile.",
    askForRating: "Ask for rating",
  },
};

const RatingStars = ({ selectedStars, onSelect }) => {
  const starImages = Array(5)
    .fill(0)
    .map((_, index) => {
      if (index < selectedStars) {
        return require("../img/icons/star2.png");
      }
      return require("../img/icons/star.png");
    });

  return (
    <View style={{ flexDirection: "row", marginVertical: 10 }}>
      {starImages.map((starImage, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            onSelect(index + 1);
          }}
        >
          <Image
            style={{ width: 30, height: 30, marginHorizontal: 5 }}
            source={starImage}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

const ProfileOthers = ({ userData, reloadData }) => {
  const navigation = useNavigation();
  const { language, miTipo, miID } = useContext(AppContext);
  const t = translations[language];
  const [isFavorited, setIsFavorited] = useState(userData?.esFavoritMeu === 1);

  const { serverAddress, token } = useContext(AppContext);
  const [isImageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImageUri, setSelectedImageUri] = useState(null);
  const [isValoraModalVisible, setValoraModalVisible] = useState(false);
  const [isSelfRatingModalVisible, setSelfRatingModalVisible] = useState(false);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);
  const [initialMode, setInitialMode] = useState("pro");
  const [rating, setRating] = useState(1);
  const navigateToChat = () => {
    navigation.navigate("ChatScreen", {
      otherUserId: userData?.id_usuari,
      otherUserPhoto: userData?.foto,
      otherUserName: userData?.nomUsuari,
      otherUserSurname: userData?.cognomUsuari,
    });
  };
  const [isProVisible, setIsProVisible] = useState(false);
  const handleOpenProModal = (mode) => {
    // Si el usuario es Premium o Colaborador, no hacer nada
    if (miTipo === "Premium" || miTipo === "Colaborador") return;
    setInitialMode(mode); // Establece el modo inicial
    setIsProVisible(true); // Muestra el modal
  };
  const isUserPremium = miTipo === "Premium";
  const isUserGuest = miTipo === "Guest";
  //console.log(miTipo)
  const user = userData;
  let iconSource;
  if (user) {
    if (user.admetTrocs === 1) {
      iconSource = require("../img/icons/troc.png");
    } else if (user.modoNegoci === "E") {
      iconSource = require("../img/icons/bag.png");
    } else {
      iconSource = require("../img/icons/location.png");
    }
  }

  const renderNeedRegisterModal = () => {
    if (isNeedRegisterVisible) {
      return (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      );
    }
    return null;
  };
  const verificarYRedirigir = () => {
    if (!miTipo) {
      // Redirige al usuario a la pantalla de inicio
      navigation.navigate("Home");
      return false;
    }
    return true;
  };

  const openPro = () => {
    setWebModalVisible(false);
    setIsProVisible(true);
  };

  const [comment, setComment] = useState("");
  const [inputBorderColor, setInputBorderColor] = useState("gray");
  const handleTextChange = (text) => {
    setInputBorderColor("gray");

    const newText = text.slice(0, 200);
    setComment(newText);
  };

  useEffect(() => {
    setIsFavorited(userData?.esFavoritMeu === 1);
  }, [userData]);

  const [isWebModalVisible, setWebModalVisible] = useState(false);

  const closeModal = () => {
    setWebModalVisible(false);
  };

  const handleTalkPress = async () => {
    if (!verificarYRedirigir()) return;
    const userId = userData?.id_usuari;

    if (!userId) {
      return;
    }
    // Añadir el efecto háptico sutil para Android y iOS
    try {
      if (Platform.OS === "ios" || Platform.OS === "android") {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Light);
      }
    } catch (error) {
      // console.log("Haptic feedback no está disponible o ha fallado", error);
    }

    const data = {
      actuacio: "XM",
      usu_Desti: userId,
    };

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      //console.log("responseData", responseData);

      if (responseData.status === "OK") {
        const { nousXatsAquestMes, maxXatsMes, tipususuari } =
          responseData.data;

        // Si el usuario es Premium, permite el paso sin restricciones
        if (tipususuari === "Premium" || tipususuari === "Supervisor") {
          navigateToChat();
          return;
        }

        // Si el usuario no es Premium o el tipususuari no existe, verifica la condición de los chats
        if (nousXatsAquestMes < maxXatsMes) {
          navigateToChat();
        } else {
          // Mostrar modal o alerta si se ha alcanzado el límite de chats
          if (Platform.OS === "web") {
            setWebModalVisible(true);
          } else {
            Alert.alert(t.tituloLimiteAlcanzado, t.limiteAlcanzado, [
              { text: t.verPro, onPress: openPro },
              { text: t.cerrar, onPress: () => {} },
            ]);
          }
        }
      }
    } catch (error) {
      // Manejar errores
      //console.error("Error al realizar la solicitud:", error);
    }
  };

  const WebModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isWebModalVisible}
      onRequestClose={closeModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translations[language].tituloLimiteAlcanzado}
          </Text>
          <Text style={styles.modalMessage}>
            {translations[language].limiteAlcanzado}
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={closeModal}>
              <Text style={styles.buttonText}>
                {translations[language].cerrar}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openPro}>
              <Text style={styles.buttonText}>
                {translations[language].verPro}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  const handleFavIconPress = async () => {
    if (!verificarYRedirigir()) return;
    const userId = userData?.id_usuari;

    if (!userId) {
      return;
    }

    if (miTipo === "Guest") {
      setIsNeedRegisterVisible(true);
      return;
    }

    const newFavoritedState = !isFavorited;

    if (Platform.OS === "ios" || Platform.OS === "android") {
      try {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      } catch (hapticError) {
        // console.log("Haptic feedback no disponible o ha fallado", hapticError);
      }
    }

    const data = {
      actuacio: "FV",
      usu_Desti: userId,
      favorit: newFavoritedState ? 1 : 0,
    };

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setIsFavorited(newFavoritedState);
      } else {
        const responseData = await response.json();
      }
    } catch (error) {}
  };

  const handleImagePress = (imageUri) => {
    setSelectedImageUri(imageUri);
    setImageModalVisible(true);
  };

  const handleShare = async (messageType = "lookAtThisProfile") => {
    const userId = userData?.id_usuari;
    const urlToShare = `${SHARE_BASE_URL}/perfil/${userId}`;
    const shareMessage = `${t[messageType]} ${urlToShare}`;

    if (Platform.OS === "web") {
      if (navigator.share) {
        try {
          await navigator.share({
            title: t.shareProfile,
            text: shareMessage,
            url: urlToShare,
          });
        } catch (error) {
          Alert.alert(t.shareError);
        }
      } else {
        Alert.alert(t.shareNotAvailable);
      }
    } else {
      try {
        await Share.share({
          message: shareMessage,
          title: t.shareProfile,
        });
      } catch (error) {
        Alert.alert(t.shareError);
      }
    }
  };

  const roundedDistancia =
    userData && userData.distancia
      ? parseFloat(userData.distancia).toFixed(1)
      : null;

  const zona = userData?.zona ? userData.zona : "Zona desconocida";

  const distancia = roundedDistancia ? ` (${roundedDistancia} Km)` : " -";

  const roundedValoracio =
    userData && userData.valoracio !== null
      ? parseFloat(userData.valoracio).toFixed(1)
      : "-";

  const imageUrl =
    userData && userData.foto
      ? `${serverAddress}/fotos/${userData.foto}`
      : null;

  const handleSendRating = async () => {
    if (!comment) {
      setInputBorderColor("red");
      return;
    }

    const dataToSend = {
      actuacio: "GV",
      usu_Desti: userData?.id_usuari,
      valoracio: rating,
      comentari: comment,
    };

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (response.ok) {
        setValoraModalVisible(false);
        reloadData();
      } else {
        const responseData = await response.json();
      }
    } catch (error) {}
  };

  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={isImageModalVisible}
        onRequestClose={() => setImageModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          onPress={() => setImageModalVisible(false)}
        >
          <Image style={styles.modalImage} source={{ uri: selectedImageUri }} />
        </TouchableOpacity>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={isProVisible}
        onRequestClose={() => setIsProVisible(false)}
      >
        <Pro
          closeModal={() => setIsProVisible(false)} // Cierra el modal
          initialMode={initialMode} // Pasa el modo actual
        />
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={isValoraModalVisible}
        onRequestClose={() => setValoraModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          onPress={() => setValoraModalVisible(false)}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                padding: 20,
                backgroundColor: "white",
                borderRadius: 10,
              }}
            >
              <Text style={styles.intro}>{t.rateThisUser}</Text>
              <RatingStars selectedStars={rating} onSelect={setRating} />
              <TextInput
                style={[styles.textInput, { borderColor: inputBorderColor }]}
                onChangeText={handleTextChange}
                value={comment}
                placeholder={t.leaveAComment}
                multiline
                maxLength={150}
              />
              <TouchableOpacity
                style={styles.sendRatingButton}
                onPress={handleSendRating}
              >
                <Text style={styles.sendRatingButtonLabel}>{t.sendRating}</Text>
              </TouchableOpacity>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={isSelfRatingModalVisible}
        onRequestClose={() => setSelfRatingModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          onPress={() => setSelfRatingModalVisible(false)}
        >
          <TouchableWithoutFeedback>
            <View style={styles.selfRatingModalView}>
              <Text style={styles.selfRatingModalTitle}>
                {t.noSelfRatingTitle}
              </Text>
              <Text style={styles.selfRatingModalMessage}>
                {t.noSelfRatingBody}
              </Text>
              <View style={styles.selfRatingButtonContainer}>
                <TouchableOpacity
                  style={styles.selfRatingCloseButton}
                  onPress={() => setSelfRatingModalVisible(false)}
                >
                  <Text style={styles.selfRatingButtonText}>{t.cerrar}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.selfRatingAskButton}
                  onPress={() => handleShare("lookAtThisProfile2")}
                >
                  <Text style={styles.selfRatingButtonText2}>
                    {t.askForRating}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      {userData ? (
        <>
          <View style={styles.header}>
            {userData.tipusUsuari === "Premium" && (
              <TouchableOpacity
                onPress={() => handleOpenProModal("pro")}
                style={{ alignSelf: "flex-start" }}
              >
                <Text style={styles.proLabel}>PRO</Text>
              </TouchableOpacity>
            )}
            {/* Botón COLAB */}
            {userData.tipusUsuari === "Colaborador" && (
              <TouchableOpacity
                onPress={() => handleOpenProModal("colab")}
                style={{ alignSelf: "flex-start" }}
              >
                <Text style={styles.colabLabel}>COLAB</Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity onPress={() => handleImagePress(imageUrl)}>
              <Image
                style={[
                  styles.profileImg,
                  userData.tipusUsuari === "Premium"
                    ? styles.premiumBorder
                    : userData.tipusUsuari === "Colaborador"
                    ? styles.colabBorder
                    : styles.defaultBorder, // Color de borde para el resto de usuarios
                ]}
                resizeMode="cover"
                source={
                  imageUrl
                    ? { uri: imageUrl }
                    : profileImages[userData.id_usuari % 10] ||
                      require("../img/profiles/default.png")
                }
              />
            </TouchableOpacity>
          </View>
          <View style={styles.descriptionContainer}>
            <Text
              style={styles.name}
            >{`${userData.nomUsuari} ${userData.cognomUsuari}`}</Text>
            <Text style={styles.presentation}>{userData.presentacio}</Text>

            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={styles.addButton}
                onPress={() => {
                  if (!verificarYRedirigir()) return;
                  if (miID === userData.id_usuari) {
                    setSelfRatingModalVisible(true);
                  } else {
                    if (Platform.OS === "ios" || Platform.OS === "android") {
                      Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Light);
                    }
                    if (isUserGuest) {
                      setIsNeedRegisterVisible(true);
                    } else {
                      setValoraModalVisible(true);
                    }
                  }
                }}
              >
                <Text style={styles.addButtonLabel}>{t.valorate}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.chatButton}
                onPress={() => {
                  if (isUserGuest) {
                    setIsNeedRegisterVisible(true);
                  } else {
                    handleTalkPress();
                  }
                }}
              >
                <Text style={styles.chatButtonLabel}>{t.chat}</Text>
              </TouchableOpacity>
            </View>
            {isNeedRegisterVisible && (
              <NeedRegister
                isVisible={isNeedRegisterVisible}
                onClose={() => setIsNeedRegisterVisible(false)}
              />
            )}
          </View>

          <View style={styles.infoIntercanvi}>
            <View style={styles.ratingContainer}>
              <Image
                style={styles.troc1Icon}
                resizeMode="cover"
                source={require("../img/icons/puntuation.png")}
              />
              <Text style={styles.text}>{roundedValoracio}</Text>
            </View>
            <View style={styles.verticalLine} />
            <View style={styles.locationContainer}>
              <Text style={styles.zonaText}>{zona}</Text>
              <Text style={styles.distanciaText}>{distancia}</Text>
            </View>
            <View style={styles.verticalLine} />
            <Image
              style={styles.troc1Icon}
              resizeMode="cover"
              source={iconSource}
            />
          </View>
          {!isUserPremium && <Publi formatAnunci="H" />}
          <View style={styles.iconsContainer}>
            <TouchableOpacity
              onPress={handleTalkPress}
              style={styles.iconButton}
            >
              {Platform.OS === "web" && <WebModal />}
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleFavIconPress}
              style={styles.iconButton}
            >
              <ImageBackground
                source={
                  isFavorited
                    ? require("../img/icons/favon.png")
                    : require("../img/icons/fav.png")
                }
                resizeMode="contain"
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => handleShare("lookAtThisProfile")}
              style={styles.iconButton}
            >
              <ImageBackground
                source={require("../img/icons/share.png")}
                resizeMode="contain"
                style={styles.sendIcon}
              />
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <Text>{t.loading}</Text>
      )}
    </View>
  );
};

const ICON_SIZE = 30;
const ICON_SPACING = 25;
const SEND_ICON_SIZE = 35;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white",
    alignSelf: "stretch",
  },
  header: {
    width: "100%",
    alignItems: "center",
    marginTop: 0,
  },
  proLabel: {
    position: "absolute",
    top: Platform.OS === "web" ? 30 : 5,
    left: Platform.OS === "web" ? 15 : 5,
    color: Colors.secondary,
    fontWeight: "bold",
    fontSize: 16,
    zIndex: 1,
  },
  profileImg: {
    borderRadius: 50,
    width: 90,
    height: 90,
    backgroundColor: Colors.primary,
  },
  name: {
    ...Fonts.dmSerifDisplayRegular24,
    color: Colors.black,
    marginTop: 10,
    textAlign: "center",
    maxWidth: 320,
  },
  presentation: {
    ...Fonts.poppinsRegular16,
    color: Colors.black,
    marginTop: 8,
    textAlign: "left",
    maxWidth: 320,
    alignSelf: "center",
  },
  infoIntercanvi: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
    marginBottom: 14,
    backgroundColor: Colors.grayLighter,
    paddingHorizontal: 10,
  },
  ratingContainer: {
    flexDirection: "column",
    alignItems: "center",
    width: 25,
  },
  locationContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 5,
    flexWrap: "nowrap",
  },
  zonaText: {
    color: Colors.black,
    ...Fonts.poppinsRegular14,
    textAlign: "center",
    flexShrink: 1,
  },
  distanciaText: {
    ...Fonts.poppinsRegular12,
    color: Colors.gray,
    textAlign: "center",
    flexShrink: 0,
    marginLeft: 4,
  },
  iconContainer: {
    flexDirection: "column",
    alignItems: "center",
    width: 60,
  },
  troc1Icon: {
    width: 14,
    height: 14,
    marginLeft: 5,
    flexShrink: 0,
  },
  verticalLine: {
    width: 1,
    height: "100%",
    backgroundColor: Colors.black,
    marginLeft: 5,
    marginRight: 5,
  },
  iconsContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
    position: "absolute",
    top: 15,
    right: 24,
    width: 50,
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    marginBottom: ICON_SPACING,
  },
  sendIcon: {
    width: 30,
    height: SEND_ICON_SIZE,
    marginBottom: ICON_SPACING,
    left: -2,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalImage: {
    width: "80%",
    height: "80%",
    resizeMode: "contain",
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  primaryBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 14,
    marginBottom: 0,
    paddingHorizontal: 30,
  },
  addButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    paddingHorizontal: 16,
    width: 100,
  },
  chatButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primary,
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    paddingHorizontal: 16,
    width: 100,
    marginLeft: 22,
  },
  addButtonLabel: {
    fontSize: 16,
    color: Colors.primary,
  },
  textInput: {
    width: 250,
    height: 100,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 10,
    textAlignVertical: "top",
    padding: 5,
  },
  intro: {
    ...Fonts.poppinsMedium16,
    color: Colors.grayDark,
    marginBottom: 10,
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  selfRatingModalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  selfRatingModalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  selfRatingModalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  selfRatingButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 14,
    marginBottom: 0,
    paddingHorizontal: 30,
  },
  selfRatingCloseButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    paddingHorizontal: 16,
    marginRight: 10,
  },
  selfRatingAskButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primary,
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    paddingHorizontal: 16,
  },
  selfRatingButtonText: {
    fontSize: 16,
    color: Colors.primary,
  },
  selfRatingButtonText2: {
    fontSize: 16,
    color: Colors.white,
  },
  spacer: {
    width: 22,
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary,
    marginHorizontal: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  chatButtonLabel: {
    fontSize: 16,
    color: "white",
  },
  sendRatingButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    paddingHorizontal: 16,
    width: 170,
    marginTop: 30,
    marginBottom: 14,
    alignSelf: "center",
  },
  sendRatingButtonLabel: {
    fontSize: 16,
    color: Colors.primary,
  },
  colabLabel: {
    position: "absolute",
    top: Platform.OS === "web" ? 30 : 5,
    left: Platform.OS === "web" ? 15 : 5,
    color: Colors.primary, // Texto COLAB en color primary
    fontWeight: "bold",
    fontSize: 16,
    zIndex: 1,
  },
  colabBorder: {
    borderWidth: 2,
    borderColor: Colors.primary, // Borde para los Colaboradores
  },
  defaultBorder: {
    borderWidth: 2,
    borderColor: Colors.grayMediumDark, // Borde para el resto de usuarios
  },
});

export default ProfileOthers;
