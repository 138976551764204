// AppContextProvider.js
import React, { createContext, useState, useEffect, useCallback } from "react";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import * as Localization from "expo-localization";
import { SERVER_ADDRESS } from "./src/docs/config.js";

// Creación del contexto para la aplicación
export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  // Estados del componente
  const [userData, setUserData] = useState(null);
  //console.log(userData)
  const [miTipo, setMiTipo] = useState(null);
  const miID = userData?.data[0]?.id_usuari;
  //console.log(miID)
  const [token, setToken] = useState(null);
  //console.log(token)
  const [otherData, setOtherData] = useState(null);
  const [language, setLanguage] = useState("ESP");
  const serverAddress = SERVER_ADDRESS;
  const [filterConfig, setFilterConfig] = useState(null);
  const [hasToken, setHasToken] = useState(null);
  const [isTokenLoading, setIsTokenLoading] = useState(true);
  const [pendingProfileId, setPendingProfileId] = useState(null);
  const [animationPerformed, setAnimationPerformed] = useState(false);
  const [isLanguageSetManually, setIsLanguageSetManually] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(false);

const requestRefresh = (value = true) => {
  setNeedsRefresh(value);
};

  const updateUser = (newData) => {
    setUserData((prevUserData) => ({ ...prevUserData, ...newData }));
  };

  
  const updateUserDataField = (field, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      data: prevUserData.data.map((item, index) => {
        if (index === 0) {
          // Asumiendo que quieres actualizar el primer elemento de data
          return { ...item, [field]: value };
        }
        return item;
      }),
    }));
  };

  // Función para actualizar el idioma en el contexto y en el backend
  const updateUserLanguage = async (newLanguage) => {
    setIsLanguageSetManually(true);
    setLanguage(newLanguage);

    // Enviar actualización al backend
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "GI",
            idioma: newLanguage,
          }),
        }
      );
      const responseData = await response.json();
      // Manejar respuesta del backend aquí si es necesario
    } catch (error) {
      //console.error("Error al actualizar el idioma:", error);
      // Manejar el error aquí si es necesario
    }
  };

  const setInitialUserData = (data) => {
    setUserData(data);
  };

  const eraseUserData = () => {
    setUserData(null);
  };

  // Función para actualizar datos en el servidor
  const updateServer = useCallback(async () => {
    // Lógica para actualizar datos del servidor
  }, [userData]);

  // useEffect para registrar cambios en filterConfig
  useEffect(() => {}, [filterConfig]);

  // Actualizar el idioma basado en userData o en el idioma del dispositivo
  useEffect(() => {
    if (!isLanguageSetManually) {
      let chosenLanguage = "ESP"; // Idioma predeterminado

      // Verificar si userData contiene información de idioma
      if (
        userData &&
        userData.data &&
        userData.data[0] &&
        userData.data[0].idioma
      ) {
        chosenLanguage = userData.data[0].idioma.toUpperCase();
      } else {
        // Utilizar el idioma del dispositivo si no hay información de idioma en userData
        const deviceLanguage = Localization.locale
          .substring(0, 2)
          .toUpperCase();
        switch (deviceLanguage) {
          case "CA":
            chosenLanguage = "CAT";
            break;
          case "ES":
            chosenLanguage = "ESP";
            break;
          case "EN":
            chosenLanguage = "ENG";
            break;
          default:
            chosenLanguage = "ESP";
        }
      }
      setLanguage(chosenLanguage);
    }
  }, [userData]);

  // Cargar token del usuario
  useEffect(() => {
    const loadToken = async () => {
      setIsTokenLoading(true);
      try {
        let savedToken;
        if (Platform.OS === "web") {
          savedToken = localStorage.getItem("userToken");
        } else {
          savedToken = await SecureStore.getItemAsync("userToken");
        }
        if (savedToken && savedToken !== token) {
          setToken(savedToken);
        }
      } catch (error) {
        //console.error("Error al cargar el token:", error);
      } finally {
        setIsTokenLoading(false);
      }
    };
  
    loadToken();
  }, []);

  // Actualizar miTipo cuando cambia userData
  useEffect(() => {
    if (userData && userData.data && userData.data[0]) {
      setMiTipo(userData.data[0].tipusUsuari);
    }
  }, [userData]);

  // Actualizar el servidor cuando cambia userData
  useEffect(() => {
    if (userData) {
      updateServer();
    }
  }, [userData, updateServer]);

  const refreshUserData = async () => {
    if (!token) return;
    try {
      const response = await fetch(`${serverAddress}/api/v1/perfils`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      if (data.status === "OK") {
        updateUser(data); // Actualiza userData, HomeScreen se re-renderizará automáticamente
      }
    } catch (error) {
      console.error("Error al refrescar datos de usuario:", error);
    }
  };

  // Actualizar el token
  const updateToken = useCallback(async (newToken) => {
    //console.log("Intentando actualizar token, token actual:", token);
    if (newToken !== token) {
      setToken(newToken);
      //console.log("Token actualizado a:", newToken);
      if (newToken === null) {
        if (Platform.OS === "web") {
          localStorage.removeItem("userToken");
        } else {
          await SecureStore.deleteItemAsync("userToken");
        }
      } else {
        const tokenString = typeof newToken === "string" ? newToken : JSON.stringify(newToken);
        if (Platform.OS === "web") {
          localStorage.setItem("userToken", tokenString);
        } else {
          await SecureStore.setItemAsync("userToken", tokenString);
        }
      }
    }
  }, [token]);
  const handleTokenExpiry = useCallback(async () => {
    // Eliminar el token guardado
    if (Platform.OS === "web") {
      localStorage.removeItem("userToken");
    } else {
      await SecureStore.deleteItemAsync("userToken");
    }
    // Actualizar el estado en el contexto
    setToken(null);
    setHasToken(false);
  }, []);
  
  const addFavorite = (newFavorite) => {
    if (userData && userData.favorites) {
      setUserData({
        ...userData,
        favorites: [...userData.favorites, newFavorite],
      });
    }
  };

  const updateOtherData = (newData) => {
    setOtherData(newData);
  };

  return (
    <AppContext.Provider
      value={{
        userData,
        updateUser,
        addFavorite,
        token,
        setToken,
        otherData,
        updateOtherData,
        serverAddress,
        language,
        updateUserLanguage,
        filterConfig,
        setFilterConfig,
        setHasToken,
        eraseUserData,
        updateToken,
        isTokenLoading,
        pendingProfileId,
        setPendingProfileId,
        animationPerformed,
        setAnimationPerformed,
        miID,
        miTipo,
        updateUserDataField,
        handleTokenExpiry,
        needsRefresh,
        requestRefresh,
        refreshUserData,
        // data,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
