import React, { useState, useContext, useRef, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Image,
  Alert,
  Platform,
} from "react-native";
import { AppContext } from "../../AppContext";
import { DataContext } from "../../DataContext";
import * as ImagePicker from "expo-image-picker";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { Fonts, Colors } from "../styles/styles.js"; // Asegúrate de que esta ruta sea correcta
import defaultProfileImage from "../img/profiles/defaultcam.png";
import { Picker as RNPicker } from "@react-native-picker/picker";

const translations = {
  ESP: {
    selectCategory: "Selecciona una categoría",
    category: "Categoría: ",
    noneSelected: "Ninguna seleccionada",
    needsub: "Selecciona una subcategoría",
    writeTitle: "Escribe aquí el título",
    titolofertaobjecte: "Ofrezco un objeto...",
    titolofertaservei: "Ofrezco un servicio de...",
    titoldemandaobjecte: "Busco un objeto...",
    titoldemandaservei: "Busco un servicio de...",
    addDescription: "Añade una breve descripción",
    useKeywords: "Aparece al buscar las palabras: ..., ...",
    useKeywordsItems: "Aparece al buscar las palabras: ..., ...",
    numericFieldsOnly: "Sólo campos numéricos",
    price: "Precio",
    hourlyRate: "€/hora",
    toBeAgreed: "Pactar",
    add: "Añadir",
    title: "Título",
    titleserviceoffer: "Título del servicio que ofreces",
    titleservicedemand: "Título del servicio que demandas",
    titleobjectoffer: "Título del objeto que ofreces",
    titleobjectdemand: "Título del objeto que demandas",
    description: "Descripción",
    descriptionserviceoffer: "Descripción del servicio que ofreces",
    descriptionservicedemand: "Descripción del servicio que demandas",
    descriptionobjectoffer: "Descripción del objeto que ofreces",
    descriptionobjectdemand: "Descripción del objeto que demandas",
    keywords: "SEPARA POR COMAS (Ej.reparaciones, manitas..)",
    keywordsItems: "SEPARA POR COMAS (Ej.TV, mesa, etc)",
    priceplace: "Pon un precio",
    titleerror: "El título no puede estar vacío",
    descriptionerror: "La descripción no puede estar vacía",
    clauerror: "Añade palabras clave",
    vendo: "Vendo",
    alquilo: "Alquilo",
    presto: "Presto",
    regalo: "Regalo",
    compro: "Compro",
    prestado: "Prestado",
    regalado: "Regalado",
    notFound: "Seleccione subcategoría",
    monthlyRate: "€/mes",
    projectRate: "€/proyecto",
    palabrasProhibidas:
      "Has usado alguna palabra prohibida, por favor, cámbiala",
    palabraNo: "Palabras prohibidas en el",
    telefono: "No puedes publicar un número de teléfono aquí",
    alertTitle: "Revisa tu entrada",
    alertMessage:
      "Recuerda, estás creando una demanda. Por favor, especifica solo las necesidades que tienes, no lo que ofreces.",
    alertMessage2:
      "Parece que has incluido una palabra típica de objetos en tu descripción de servicio. Verifica y asegúrate de que estás añadiendo servicios.",
    alertMessage3:
      "Has mencionado una palabra que usualmente se asocia con servicios. Por favor, asegúrate de que estás añadiendo objetos.",
    alertMessage4:
      "Recuerda, estás creando una oferta. Por favor, especifica solo lo que ofreces a otros usuarios, no lo que buscas.",
    demandaPrefixObjetos: "Busco ",
    demandaPrefixServicios: "Busco un servicio de ",
    demandaDescServiciosPrefix: "Necesito ayuda con ",
  },
  CAT: {
    selectCategory: "Selecciona una categoria",
    category: "Categoria: ",
    noneSelected: "Cap seleccionada",
    needsub: "Selecciona una subcategoria",
    writeTitle: "Escriu aquí el títol",
    titolofertaobjecte: "Ofereixo un objecte...",
    titolofertaservei: "Ofereixo un servei de...",
    titoldemandaobjecte: "Busco un objecte...",
    titoldemandaservei: "Busco un servei de...",
    addDescription: "Afegeix una breu descripció",
    useKeywords: "Apareix al buscar les paraules: ..., ...",
    useKeywordsItems: "Apareix al buscar les paraules: ..., ...",
    numericFieldsOnly: "Només camps numèrics",
    price: "Preu",
    hourlyRate: "€/hora",
    toBeAgreed: "Pactar",
    add: "Afegeix",
    title: "Títol",
    titleserviceoffer: "Títol del servei que ofereixes",
    titleservicedemand: "Títol del servei que demandes",
    titleobjectoffer: "Títol de l'objecte que ofereixes",
    titleobjectdemand: "Títol de l'objecte que demandes",
    description: "Descripció",
    descriptionserviceoffer: "Descripció del servei que ofereixes",
    descriptionservicedemand: "Descripció del servei que demandes",
    descriptionobjectoffer: "Descripció de l'objecte que ofereixes",
    descriptionobjectdemand: "Descripció de l'objecte que demandes",
    keywords: "SEPARA PER COMES (Ex.reparacions, manetes..)",
    keywordsItems: "SEPARA PER COMES (Ex.TV, taula, etc)",
    priceplace: "Posa un preu",
    titleerror: "El títol no pot estar buit",
    descriptionerror: "La descripció no pot estar buida",
    clauerror: "Afegeix paraules clau",
    vendo: "Venc",
    alquilo: "Llogo",
    presto: "Deixo",
    regalo: "Regalo",
    compro: "Compro",
    prestado: "Prestat",
    regalado: "Regalat",
    notFound: "Seleccioni subcategoria",
    monthlyRate: "€/mes",
    projectRate: "€/projecte",
    palabrasProhibidas:
      "Has utilitzat alguna paraula prohibida, si us plau, canvia-la",
    palabraNo: "Paraules prohibides en el",
    telefono: "No pots publicar un número de telèfon aquí",
    alertTitle: "Revisa la teva entrada",
    alertMessage:
      "Recorda, estàs creant una demanda. Si us plau, especifica només les necessitats que tens, no el que ofereixes.",
    alertMessage2:
      "Sembla que has inclòs una paraula típica d'objectes en la teva descripció de servei. Verifica i assegura't que estàs afegint serveis.",
    alertMessage3:
      "Has mencionat una paraula que usualment s'associa amb serveis. Si us plau, assegura't que estàs afegint objectes.",
    alertMessage4:
      "Recorda, estàs creant una oferta. Si us plau, especifica només el que oferiràs a altres usuaris, no el que busques.",
    demandaPrefixObjetos: "Busco ",
    demandaPrefixServicios: "Busco un servei ",
    demandaDescServiciosPrefix: "Necessito ajuda amb ",
  },
  ENG: {
    selectCategory: "Select a category",
    category: "Category: ",
    noneSelected: "None selected",
    needsub: "Select a subcategory",
    writeTitle: "Write the title here",
    titolofertaobjecte: "I offer an object...",
    titolofertaservei: "I offer a service of...",
    titoldemandaobjecte: "I need an object...",
    titoldemandaservei: "I need a service of...",
    addDescription: "Add a brief description",
    useKeywords: "Appears when searching the words: ..., ...",
    useKeywordsItems: "Appears when searching the words: ..., ...",
    numericFieldsOnly: "Numeric fields only",
    price: "Price",
    hourlyRate: "€/hour",
    toBeAgreed: "To agree",
    add: "Add",
    title: "Title",
    titleserviceoffer: "Title of the service you offer",
    titleservicedemand: "Title of the service you demand",
    titleobjectoffer: "Title of the object you offer",
    titleobjectdemand: "Title of the object you demand",
    description: "Description",
    descriptionserviceoffer: "Description of the service you offer",
    descriptionservicedemand: "Description of the service you demand",
    descriptionobjectoffer: "Description of the object you offer",
    descriptionobjectdemand: "Description of the object you demand",
    keywords: "SEPARATE BY COMMAS (Ex.repairs, handyman..)",
    keywordsItems: "SEPARATE BY COMMAS (Ex.TV, table, etc)",
    priceplace: "Put a price",
    titleerror: "The title cannot be empty",
    descriptionerror: "The description cannot be empty",
    clauerror: "Add keywords",
    vendo: "Sell",
    alquilo: "Rent",
    presto: "Lend",
    regalo: "Gift",
    compro: "Buy",
    prestado: "Borrowed",
    regalado: "Gifted",
    notFound: "Select subcategory",
    monthlyRate: "€/month",
    projectRate: "€/project",
    palabrasProhibidas: "You have used a forbidden word, please change it",
    palabraNo: "Forbidden words in the",
    telefono: "You can't post a phone number here",
    alertTitle: "Check your input",
    alertMessage:
      "Remember, you are creating a demand. Please specify only the needs you have, not what you offer.",
    alertMessage2:
      "It seems you have included a typical object word in your service description. Verify and make sure you are adding services.",
    alertMessage3:
      "You have mentioned a word that is usually associated with services. Please make sure you are adding objects.",
    alertMessage4:
      "Remember, you are creating an offer. Please specify only what you will offer to other users, not what you are looking for.",
    demandaPrefixObjetos: "I need ",
    demandaPrefixServicios: "I need a service of ",
    demandaDescServiciosPrefix: "I need help with ",
  },
};

class ExpandingTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  focus() {
    this.textInput && this.textInput.focus();
  }

  render() {
    return (
      <TextInput
        {...this.props}
        ref={(view) => (this.textInput = view)}
        multiline
        onContentSizeChange={(event) => {
          if (event && event.nativeEvent && event.nativeEvent.contentSize) {
            this.setState({
              height: event.nativeEvent.contentSize.height,
            });
          }
          this.props.onContentSizeChange &&
            this.props.onContentSizeChange(event);
        }}
        style={[this.props.style, { height: Math.max(35, this.state.height) }]}
      />
    );
  }
}

const Picker = ({
  options = [],
  onValueChange,
  onObjectSelected,
  language,
}) => {
  const [selected, setSelected] = useState(0);

  return (
    <View style={pickerStyles.container}>
      {options.map((option, index) => (
        <TouchableOpacity
          key={index}
          style={[
            pickerStyles.option,
            selected === index && pickerStyles.selectedOption,
          ]}
          onPress={() => {
            setSelected(index);
            onValueChange(option);
            if (option === "objetos" && onObjectSelected) {
              onObjectSelected();
            }
          }}
        >
          <Text
            style={
              selected === index
                ? pickerStyles.selectedText
                : pickerStyles.optionText
            }
          >
            {translations[language][option] || option}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const OfreceServicio = ({
  selectedCategory,
  selectedSubCategoryId,
  closeModal,
  type,
  selectedOption,
}) => {
  const { data, restrictedWords } = useContext(DataContext);
  const { serverAddress, language, token, userData, updateUser } =
    useContext(AppContext);

  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [palabrasClave, setPalabrasClave] = useState("");
  const [pickerOption, setPickerOption] = useState("vendo");
  const [showImage, setShowImage] = useState(false);
  const [precioHora, setPrecioHora] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const [currency, setCurrency] = useState("€");
  const [precioError, setPrecioError] = useState(false);
  const [tituloError, setTituloError] = useState(false);
  const [descripcionError, setDescripcionError] = useState(false);
  const [palabrasClaveError, setPalabrasClaveError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [subCategoryError, setSubCategoryError] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [alertGeneralShown, setAlertGeneralShown] = useState(false);
  const [alertObjetosShown, setAlertObjetosShown] = useState(false);
  const [alertServiciosShown, setAlertServiciosShown] = useState(false);
  const [alertOfferShown, setAlertOfferShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selection, setSelection] = useState({ start: 0, end: 0 });
  const [descripcionSelection, setDescripcionSelection] = useState({
    start: 0,
    end: 0,
  });

  const t = translations[language];

  const placeholders = {
    servicios: {
      useKeywords: t.useKeywords,
      keywords: t.keywords,
    },
    objetos: {
      useKeywords: t.useKeywordsItems,
      keywords: t.keywordsItems,
    },
  };

  // Función para obtener los placeholders y keywords correctos
  const getPlaceholders = (option) => {
    return placeholders[option] || placeholders.servicios;
  };

  const getTitlePlaceholder = () => {
    if (type !== "ofrece") {
      if (selectedOption === "objetos") {
        return t.titoldemandaobjecte; // "Busco un objecte..."
      } else if (selectedOption === "servicios") {
        return t.titoldemandaservei; // "Busco un servei de..."
      }
    } else {
      if (selectedOption === "objetos") {
        return t.titolofertaobjecte;
      } else if (selectedOption === "servicios") {
        return t.titolofertaservei;
      }
    }
    return t.writeTitle; // Placeholder por defecto
  };

  const getTitlePrefix = () => {
    if (type !== "ofrece") {
      if (selectedOption === "objetos") {
        // Solo "Busco "
        return t.demandaPrefixObjetos;
      } else if (selectedOption === "servicios") {
        // "Busco un servei "
        return t.demandaPrefixServicios;
      }
    }
    return "";
  };

  const getDescripcionPrefix = () => {
    if (type !== "ofrece") {
      if (selectedOption === "servicios") {
        return t.demandaDescServiciosPrefix;
      }
    }
    return "";
  };

  const { useKeywords, keywords } = getPlaceholders(selectedOption);

  useEffect(() => {
    if (type === "ofrece") {
      if (selectedOption === "objetos") {
        setShowImage(true);
        setCurrency("€");
      } else if (selectedOption === "servicios") {
        setCurrency("€/h");
      }
    }
  }, [selectedOption, type]);

  useEffect(() => {
    // Resetear el título y descripción al cambiar entre Objetos y Servicios
    setTitulo("");
    setDescripcion("");
    setSelection({ start: 0, end: 0 });
    setDescripcionSelection({ start: 0, end: 0 });
  }, [selectedOption]);

  const palabrasProhibidasNeed = [
    "ofrezco",
    "ofereixo",
    "I offer",
    "busco un empleo",
    "busco trabajo",
    "encontrar trabajo",
    "busco el empleo",
    "busco feina",
    "busco curro",
    "busco el curro",
    "busco empleador",
    "seeking employment",
    "vendo",
    "venc",
    "realizo",
    "realitzo",
    "I do",
    "hago",
    "faig",
    "realizó",
    "doy",
    "limpiamos",
    "pintamos",
  ];

  const palabrasProhibidasOffer = [
    "necesito",
    "busco",
    "pido",
    "necessito",
    "pediria",
    "demano",
  ];

  const palabrasProhibidasObjetos = [
    "cerrajero",
    "electricista",
    "pintor",
    "fontanero",
    "instalador",
    "reparador",
    "albañil",
    "carpintero",
    "mecánico",
    "montador",
    "canguro",
    "cocinero",
    "mantenimiento",
    "modista",
    "cuidador",
    "jardinero",
    "entrenador",
    "esteticista",
    "masajista",
    "nutricionista",
    "psicología",
    "peluquería",
    "limpieza",
    "fotógrafo",
    "músico",
    "tatuador",
    "diseñador",
    "desarrollo",
    "refuerzo",
    "logopedia",
    "tecnologías",
    "mudanzas",
    "chófer",
    "animación",
    "planificación",
    "cátering",
    "seguridad",
    "camarero",
    "restaurante",
    "dependiente",
    "atención",
    "comercial",
    "academia",
    "formación",
    "educación",
  ];

  const palabrasProhibidasServicios = [
    "vivienda",
    "housing",
    "habitatge",
    "habitación",
    "room",
    "habitació",
    "habitacion",
    "parking",
    "aparcament",
    "vehículo",
    "vehicle",
    "cotxe",
    "coche",
    "bicicleta",
    "furniture",
    "technology",
    "herramientas",
    "tools",
    "eines",
    "electrodoméstico",
    "appliance",
    "electrodomèstic",
    "material deportivo",
    "sports equipment",
    "material esportiu",
    "material educativo",
    "educational material",
    "material educatiu",
    "equipo médico",
    "medical equipment",
    "equip mèdic",
    "instrumento",
    "instrument",
    "joguina",
    "libros",
    "books",
    "llibres",
    "maquinaria",
    "machinery",
    "maquinària",
    "commercial property",
    "embarcación",
  ];

  const removeEmojis = (text) => {
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\u2011-\u26FF])/g,
      ""
    );
  };

  const handleInputChange = (text, fieldName) => {
    let cleanText = text.replace(/^\n+/g, ""); // Eliminar saltos de línea iniciales

    if (fieldName === "titulo") {
      cleanText = removeEmojis(cleanText); // Eliminar emojis del título
    }
    if (fieldName === "palabrasClave") {
      // Eliminar emojis en las palabras clave
      cleanText = removeEmojis(cleanText);
    }

    const palabrasProhibidas =
      type !== "ofrece" ? palabrasProhibidasNeed : palabrasProhibidasOffer;

    const regexProhibidas = new RegExp(
      `\\b(${palabrasProhibidas.join("|")})\\b`,
      "gi"
    );

    if (regexProhibidas.test(cleanText)) {
      const textoSinProhibidas = cleanText.replace(regexProhibidas, "").trim();
      focusHandlers[fieldName].setter(textoSinProhibidas);

      if (type !== "ofrece") {
        Alert.alert(t.alertTitle, t.alertMessage);
      } else {
        Alert.alert(t.alertTitle, t.alertMessage4);
      }
      return;
    }

    if (fieldName === "titulo" && type !== "ofrece") {
      const prefix = getTitlePrefix();
      const minPrefixLength = prefix.length;

      if (
        cleanText.length < minPrefixLength ||
        !cleanText.startsWith(prefix.substring(0, minPrefixLength))
      ) {
        setTitulo(prefix.substring(0, minPrefixLength));
      } else {
        setTitulo(cleanText);
      }
    } else if (
      fieldName === "descripcion" &&
      type !== "ofrece" &&
      selectedOption === "servicios"
    ) {
      const prefix = getDescripcionPrefix();
      const minPrefixLength = prefix.length;

      if (
        cleanText.length < minPrefixLength ||
        !cleanText.startsWith(prefix.substring(0, minPrefixLength))
      ) {
        setDescripcion(prefix.substring(0, minPrefixLength));
      } else {
        setDescripcion(cleanText);
      }
    } else {
      focusHandlers[fieldName].setter(cleanText);
    }

    const palabrasEspecificasEncontradas =
      encuentraPalabrasEspecificas(cleanText);
    if (palabrasEspecificasEncontradas.length > 0 && !alertShown) {
      if (selectedOption === "objetos") {
        if (!alertObjetosShown) {
          Alert.alert(t.alertTitle, t.alertMessage3);
          setAlertObjetosShown(true);
        }
      } else if (selectedOption === "servicios") {
        if (!alertServiciosShown) {
          Alert.alert(t.alertTitle, t.alertMessage2);
          setAlertServiciosShown(true);
        }
      }
      setAlertShown(true);
    }
  };

  useEffect(() => {
    setAlertShown(false);
    setAlertObjetosShown(false);
    setAlertServiciosShown(false);
    setAlertOfferShown(false);
    setAlertGeneralShown(false);
  }, [selectedOption]);

  const encuentraPalabrasEspecificas = (texto) => {
    const palabras = texto.toLowerCase().split(/\s+/);
    let palabrasProhibidas = [];

    if (selectedOption === "objetos") {
      palabrasProhibidas = palabrasProhibidasObjetos;
    } else if (selectedOption === "servicios") {
      palabrasProhibidas = palabrasProhibidasServicios;
    }

    return palabras.filter((palabra) => palabrasProhibidas.includes(palabra));
  };

  const encuentraPalabrasProhibidas = (texto) => {
    const palabras = texto
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(/\s+/);

    const palabrasProhibidasNormalizadas = restrictedWords.map((p) =>
      p
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );

    const tieneSecuenciaNumericaProhibida = /\d{9,}/.test(
      texto.replace(/[^\d]/g, "")
    );

    const palabrasEncontradas = palabras.filter(
      (palabra) =>
        palabrasProhibidasNormalizadas.includes(palabra) ||
        tieneSecuenciaNumericaProhibida
    );

    return palabrasEncontradas;
  };

  const animatedValues = {
    titulo: useRef(new Animated.Value(0)).current,
    descripcion: useRef(new Animated.Value(0)).current,
    palabrasClave: useRef(new Animated.Value(0)).current,
    precioHora: useRef(new Animated.Value(0)).current,
  };

  const focusHandlers = {
    titulo: {
      state: useState(false),
      animation: animatedValues.titulo,
      setter: setTitulo,
    },
    descripcion: {
      state: useState(false),
      animation: animatedValues.descripcion,
      setter: setDescripcion,
    },
    palabrasClave: {
      state: useState(false),
      animation: animatedValues.palabrasClave,
      setter: setPalabrasClave,
    },
    precioHora: {
      state: useState(false),
      animation: animatedValues.precioHora,
      setter: setPrecioHora,
    },
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const processField = (text) => {
    if (text && text === text.toUpperCase()) {
      return capitalizeFirstLetter(text.toLowerCase());
    }
    return capitalizeFirstLetter(text);
  };

  const handleFocus = (fieldName) => {
    const { state, animation } = focusHandlers[fieldName];
    state[1](true);
    Animated.timing(animation, {
      toValue: 1,
      duration: 150,
      useNativeDriver: false,
    }).start();

    if (fieldName === "titulo" && type !== "ofrece" && titulo === "") {
      const prefix = getTitlePrefix();
      setTitulo(prefix);
      setSelection({ start: prefix.length, end: prefix.length });
    }

    if (
      fieldName === "descripcion" &&
      type !== "ofrece" &&
      selectedOption === "servicios" &&
      descripcion === ""
    ) {
      const prefix = getDescripcionPrefix();
      setDescripcion(prefix);
      setDescripcionSelection({ start: prefix.length, end: prefix.length });
    }
  };

  const handleBlur = (fieldName) => {
    const { state, animation } = focusHandlers[fieldName];
    state[1](false);
    if (!state[0]) {
      Animated.timing(animation, {
        toValue: 0,
        duration: 150,
        useNativeDriver: false,
      }).start();
    }
  };

  const findCategoryName = (catId, subCatId) => {
    if (subCatId && selectedOption === "servicios") {
      for (const tipo of data.tipo) {
        if (tipo.tipo === "Servicios") {
          for (const cat of tipo.categorias) {
            if (cat.id_cat === catId) {
              const foundSubCat = cat.subcategorias.find(
                (subcat) => subcat.id_subcat === subCatId
              );
              if (foundSubCat) {
                return foundSubCat[`nombre_${language}`] || foundSubCat.nombre;
              }
            }
          }
        }
      }
    }

    if (selectedOption === "objetos") {
      for (const tipo of data.tipo) {
        if (tipo.tipo === "Objetos") {
          for (const cat of tipo.categorias) {
            if (cat.id_subcat === catId) {
              return cat[`nombre_${language}`] || cat.nombre;
            }
          }
        }
      }
    }

    return translations[language].notFound;
  };

  const handlePrecioChange = (text) => {
    if (/^\d+(\,\d{0,2})?$/.test(text) || text === "") {
      setPrecioError(false);
      setPrecioHora(text);
    } else {
      setPrecioError(true);
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (titulo.trim() === "") {
      setTituloError(true);
      isValid = false;
    } else {
      setTituloError(false);
    }

    if (descripcion.trim() === "") {
      setDescripcionError(true);
      isValid = false;
    } else {
      setDescripcionError(false);
    }

    if (palabrasClave.trim() === "") {
      setPalabrasClaveError(true);
      isValid = false;
    } else {
      setPalabrasClaveError(false);
    }

    if (!selectedCategory) {
      setCategoryError(true);
      isValid = false;
    } else {
      setCategoryError(false);
    }

    if (selectedOption === "servicios" && !selectedSubCategoryId) {
      setSubCategoryError(true);
      isValid = false;
    } else {
      setSubCategoryError(false);
    }

    return isValid;
  };

  const handleAddButton = () => {
    if (isSubmitting) return;

    if (validateFields()) {
      setIsSubmitting(true);
      let palabrasClaveProcesadas = palabrasClave
        .split(",")
        .map((keyword) => {
          keyword = keyword.trim();
          keyword = keyword.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDDE0-\uDDFF])/g,
            ""
          );

          if (!/^(\".*\"|\'.*\')$/.test(keyword)) {
            if (keyword.includes(" ")) {
              // Aplicar processField a cada palabra clave
              return `"${processField(keyword)}"`;
            } else {
              return processField(keyword);
            }
          }
          return processField(keyword);
        })
        .join(", ");

      let palabrasProhibidasEncontradas = encuentraPalabrasProhibidas(
        titulo
      ).concat(
        encuentraPalabrasProhibidas(descripcion),
        encuentraPalabrasProhibidas(palabrasClaveProcesadas)
      );

      if (palabrasProhibidasEncontradas.length > 0) {
        if (palabrasProhibidasEncontradas.some((p) => /\b\d{9}\b/.test(p))) {
          if (Platform.OS === "web") {
            window.alert(t.telefono);
          } else {
            Alert.alert(t.telefono);
          }
        } else {
          if (Platform.OS === "web") {
            window.alert(t.palabrasProhibidas);
          } else {
            Alert.alert(t.palabrasProhibidas);
          }
        }
        setIsSubmitting(false);
        return;
      }

      enviarAlServidor(palabrasClaveProcesadas);
    }
  };

  const enviarAlServidor = (palabrasClaveProcesadas) => {
    let subCatId;
    if (selectedOption === "servicios") {
      subCatId = selectedSubCategoryId;
    } else if (selectedOption === "objetos") {
      subCatId = selectedCategory;
    }

    if (!subCatId) {
      setIsSubmitting(false);
      return;
    }

    let finalPrecioHora;
    if (pickerOption === "regalo" || pickerOption === "presto") {
      finalPrecioHora = "";
    } else if (currency === t.toBeAgreed) {
      finalPrecioHora = t.toBeAgreed;
    } else if (precioHora.trim() === "") {
      finalPrecioHora = "";
    } else {
      finalPrecioHora = precioHora + " " + currency;
    }

    let tipoTransac;
    if (selectedOption === "servicios") {
      tipoTransac = type === "ofrece" ? "O" : "D";
    } else {
      tipoTransac = translatePickerOptionToTipoTransac(pickerOption);
    }

    // Aplicar processField a Título y Descripción
    const tituloAjustado = processField(titulo);
    const descripcionAjustada = processField(descripcion);

    const payload = {
      subcat: subCatId,
      titul: tituloAjustado,
      descripcio: descripcionAjustada,
      paraulesClau: palabrasClaveProcesadas,
      preu: finalPrecioHora,
      tipoTransac: tipoTransac,
      aplica_OD: type === "ofrece" ? "O" : "D",
      foto: base64Image,
    };

    const apiUrl =
      type === "ofrece"
        ? `${serverAddress}/api/v1/perfils/novaoferta`
        : `${serverAddress}/api/v1/perfils/novademanda`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          return fetch(
            type === "ofrece"
              ? `${serverAddress}/api/v1/perfils/mevesofertes`
              : `${serverAddress}/api/v1/perfils/mevesdemandes`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          throw new Error("Error al realizar la operación");
        }
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const updatedData = {
            ...userData.data[0],
            mevesOfertes:
              type === "ofrece" ? data.data : userData.data[0].mevesOfertes,
            mevesDemandes:
              type !== "ofrece" ? data.data : userData.data[0].mevesDemandes,
          };
          updateUser({ data: [updatedData] });
          closeModal();
        } else {
          throw new Error("Error al obtener la lista actualizada");
        }
      })
      .catch((error) => {
        //console.error("Error en la solicitud fetch:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const createLabelStyle = (focusAnim, value, fieldName, errorState) => {
    const baseStyle = {
      position: "absolute",
      left: 10,
      top: focusAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [15, 0],
      }),
      fontSize: focusAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 12],
      }),
      color: focusAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [Colors.grayDark, Colors.primary],
      }),
      opacity:
        (errorState || value || focusHandlers[fieldName].state[0]) &&
        !(value === "Pactar")
          ? 1
          : 0,
    };

    if (errorState) {
      return {
        ...baseStyle,
        color: "red",
      };
    }
    return baseStyle;
  };

  const requestPermissions = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Se requieren permisos para acceder a la cámara o la galería.");
      }
    }
  };

  const resizeAndCompressImage = async (uri) => {
    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 500 } }],
      {
        compress: 0.5,
        format: SaveFormat.JPEG,
      }
    );
    return manipResult.uri;
  };

  const handleImageResult = async (result) => {
    if (!result.canceled && result.assets) {
      const resizedImageUri = await resizeAndCompressImage(
        result.assets[0].uri
      );
      setSelectedImage({ uri: resizedImageUri });

      const imageData = await fetch(resizedImageUri);
      const blob = await imageData.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => setBase64Image(reader.result.split(",")[1]);
    }
  };

  const showImagePickerOptions = () => {
    if (Platform.OS === "web") {
      pickImage();
    } else {
      Alert.alert("Subir foto", "Elige una opción", [
        { text: "Cámara", onPress: takePhoto },
        { text: "Galería", onPress: pickImage },
        { text: "Cancelar", style: "cancel" },
      ]);
    }
  };

  const takePhoto = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const pickImage = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const getLabel = (fieldName) => {
    if (fieldName === "titulo") {
      if (tituloError) {
        return t.titleerror;
      } else {
        let key = "";
        if (type === "ofrece") {
          if (selectedOption === "servicios") {
            key = "titleserviceoffer";
          } else if (selectedOption === "objetos") {
            key = "titleobjectoffer";
          }
        } else {
          if (selectedOption === "servicios") {
            key = "titleservicedemand";
          } else if (selectedOption === "objetos") {
            key = "titleobjectdemand";
          }
        }
        return t[key] || t.title;
      }
    } else if (fieldName === "descripcion") {
      if (descripcionError) {
        return t.descriptionerror;
      } else {
        let key = "";
        if (type === "ofrece") {
          if (selectedOption === "servicios") {
            key = "descriptionserviceoffer";
          } else if (selectedOption === "objetos") {
            key = "descriptionobjectoffer";
          }
        } else {
          if (selectedOption === "servicios") {
            key = "descriptionservicedemand";
          } else if (selectedOption === "objetos") {
            key = "descriptionobjectdemand";
          }
        }
        return t[key] || t.description;
      }
    } else if (fieldName === "palabrasClave") {
      return palabrasClaveError ? t.clauerror : keywords;
    }
  };

  const translatePickerOptionToTipoTransac = (option) => {
    const lowerOption = option.toLowerCase();
    switch (lowerOption) {
      case "vendo":
        return "V";
      case "alquilo":
        return "L";
      case "presto":
      case "prestado":
        return "P";
      case "regalo":
      case "regalado":
        return "G";
      default:
        return "";
    }
  };

  const pickerOptions =
    type === "ofrece"
      ? ["vendo", "alquilo", "presto", "regalo"]
      : ["compro", "alquilo", "prestado", "regalado"];

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Text style={styles.categoryText}>
          <Text style={[styles.label, categoryError ? { color: "red" } : {}]}>
            {categoryError ? t.selectCategory : t.category}
          </Text>
          {findCategoryName(selectedCategory, selectedSubCategoryId) ||
            t.noneSelected}
        </Text>
        <View style={{ height: 20 }} />

        {selectedOption === "objetos" && (
          <Picker
            options={pickerOptions}
            onValueChange={(value) => setPickerOption(value)}
            onObjectSelected={() => setShowImage(true)}
            language={language}
          />
        )}

        <View style={{ height: 20 }} />

        {["titulo", "descripcion", "palabrasClave"].map((fieldName, index) => {
          const handleSelectionChange = (event, fieldName) => {
            const { selection } = event.nativeEvent;
            if (
              type !== "ofrece" &&
              ((fieldName === "titulo" && selectedOption) ||
                (fieldName === "descripcion" && selectedOption === "servicios"))
            ) {
              const prefix =
                fieldName === "titulo"
                  ? getTitlePrefix()
                  : getDescripcionPrefix();
              const minPrefixLength = prefix.length;

              if (selection.start < minPrefixLength) {
                const newSelection = {
                  start: minPrefixLength,
                  end:
                    selection.end < minPrefixLength
                      ? minPrefixLength
                      : selection.end,
                };
                if (fieldName === "titulo") {
                  setSelection(newSelection);
                } else {
                  setDescripcionSelection(newSelection);
                }
              } else {
                if (fieldName === "titulo") {
                  setSelection(selection);
                } else {
                  setDescripcionSelection(selection);
                }
              }
            }
          };

          return (
            <View key={fieldName} style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Animated.Text
                  style={createLabelStyle(
                    animatedValues[fieldName],
                    {
                      titulo: titulo,
                      descripcion: descripcion,
                      palabrasClave: palabrasClave,
                    }[fieldName],
                    fieldName,
                    {
                      titulo: tituloError,
                      descripcion: descripcionError,
                      palabrasClave: palabrasClaveError,
                    }[fieldName]
                  )}
                >
                  {getLabel(fieldName)}
                </Animated.Text>

                <ExpandingTextInput
                  style={[
                    styles.input,
                    focusHandlers[fieldName].state[0]
                      ? styles.inputFocused
                      : {},
                  ]}
                  maxLength={fieldName === "descripcion" ? 200 : 44}
                  placeholder={
                    {
                      titulo: getTitlePlaceholder(),
                      descripcion: t.addDescription,
                      palabrasClave: useKeywords,
                    }[fieldName]
                  }
                  value={
                    {
                      titulo: titulo,
                      descripcion: descripcion,
                      palabrasClave: palabrasClave,
                    }[fieldName]
                  }
                  onChangeText={(text) => handleInputChange(text, fieldName)}
                  onFocus={() => handleFocus(fieldName)}
                  onBlur={() => handleBlur(fieldName)}
                  multiline={fieldName === "descripcion"}
                  onSelectionChange={
                    (fieldName === "titulo" ||
                      (fieldName === "descripcion" &&
                        selectedOption === "servicios")) &&
                    type !== "ofrece"
                      ? (event) => handleSelectionChange(event, fieldName)
                      : undefined
                  }
                  selection={
                    (fieldName === "titulo" ||
                      (fieldName === "descripcion" &&
                        selectedOption === "servicios")) &&
                    type !== "ofrece"
                      ? fieldName === "titulo"
                        ? selection
                        : descripcionSelection
                      : undefined
                  }
                />
              </View>
              {encuentraPalabrasProhibidas(
                {
                  titulo: titulo,
                  descripcion: descripcion,
                  palabrasClave: palabrasClave,
                }[fieldName]
              ).length > 0 && (
                <Text style={{ color: "red" }}>
                  {t.palabraNo} {fieldName}:{" "}
                  {encuentraPalabrasProhibidas(
                    {
                      titulo: titulo,
                      descripcion: descripcion,
                      palabrasClave: palabrasClave,
                    }[fieldName]
                  ).join(", ")}
                </Text>
              )}
              {index < 2 && <View style={styles.separator} />}
            </View>
          );
        })}

        {type === "ofrece" &&
          (pickerOption === "vendo" || pickerOption === "alquilo") && (
            <View style={styles.inputWrapper}>
              <View style={styles.separator} />
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View
                  style={[styles.inputContainer, { flex: 1.6, marginRight: 5 }]}
                >
                  <Animated.Text
                    style={[
                      createLabelStyle(
                        animatedValues["precioHora"],
                        precioHora,
                        "precioHora"
                      ),
                      precioError ? { color: "red" } : {},
                    ]}
                  >
                    {precioError ? t.numericFieldsOnly : t.price}
                  </Animated.Text>
                  <TextInput
                    editable={currency !== t.toBeAgreed}
                    style={[
                      styles.input,
                      focusHandlers["precioHora"].state[0]
                        ? styles.inputFocused
                        : {},
                      precioError ? { borderColor: "red", borderWidth: 1 } : {},
                    ]}
                    keyboardType="decimal-pad"
                    maxLength={8}
                    placeholder={t.priceplace}
                    value={precioHora}
                    onChangeText={handlePrecioChange}
                    onFocus={() => handleFocus("precioHora")}
                    onBlur={() => handleBlur("precioHora")}
                  />
                </View>
                <RNPicker
                  selectedValue={currency}
                  style={[
                    { height: 50, width: "45%" },
                    Platform.select({
                      ios: { marginTop: -180, itemSpace: 50 },
                      android: {},
                      web: { marginTop: -13 },
                    }),
                  ]}
                  itemStyle={{ fontSize: 12 }}
                  onValueChange={(itemValue) => {
                    setCurrency(itemValue);
                    if (itemValue === t.toBeAgreed) {
                      setPrecioHora(t.toBeAgreed);
                    } else {
                      if (precioHora === t.toBeAgreed) {
                        setPrecioHora("");
                      }
                    }
                  }}
                >
                  {selectedOption === "objetos" && (
                    <RNPicker.Item label={t.hourlyRate} value="€/h" />
                  )}
                  <RNPicker.Item label="€" value="€" />
                  {(selectedOption === "servicios" ||
                    selectedOption === "subcategoría") && (
                    <RNPicker.Item label={t.hourlyRate} value="€/h" />
                  )}
                  <RNPicker.Item label={t.toBeAgreed} value={t.toBeAgreed} />

                  <RNPicker.Item label={t.monthlyRate} value="€/mes" />
                  <RNPicker.Item label={t.projectRate} value="€/proj" />
                </RNPicker>
              </View>
              <View style={styles.separator} />
            </View>
          )}

        {type === "ofrece" && selectedOption === "objetos" && showImage && (
          <TouchableOpacity onPress={showImagePickerOptions}>
            <Image
              source={selectedImage ? selectedImage : defaultProfileImage}
              style={styles.profileImage}
            />
          </TouchableOpacity>
        )}
        {subCategoryError && (
          <Text style={{ color: "red", textAlign: "center", marginTop: 10 }}>
            {t.needsub}
          </Text>
        )}
        <TouchableOpacity
          style={[
            styles.addButton,
            isSubmitting && { backgroundColor: Colors.grayLight },
          ]}
          onPress={handleAddButton}
          disabled={isSubmitting}
        >
          <Text style={styles.addButtonLabel}>{t.add}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const pickerStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.grayMedium,
    backgroundColor: Colors.grayLighter,
    overflow: "hidden",
  },
  option: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderColor: Colors.grayMedium,
    borderRightWidth: 1,
  },
  selectedOption: {
    backgroundColor: Colors.primary,
  },
  optionText: {
    ...Fonts.poppinsMedium12,
    color: Colors.grayDark,
  },
  selectedText: {
    ...Fonts.poppinsMedium12,
    color: Colors.white,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    marginTop: 0,
  },
  card: {
    width: 340,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.25,
    shadowRadius: 6,
    shadowOffset: { width: 0, height: 0 },
    padding: 20,
    ...Platform.select({
      android: {
        elevation: 6,
      },
    }),
  },
  categoryText: {
    fontSize: 16,
    fontWeight: "400",
    color: "#000",
  },
  addButton: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "white",
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    justifyContent: "center",
    paddingHorizontal: 16,
    width: 194,
  },
  addButtonLabel: {
    fontSize: 16,
    color: Colors.primary,
  },
  label: {
    fontWeight: "400",
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.grayLight,
    alignSelf: "flex-start",
    marginBottom: 15,
  },
  categorySeparator: {
    height: 20,
  },
  inputContainer: {
    backgroundColor: Colors.grayLighter,
    borderRadius: 6,
    padding: 10,
    marginBottom: 15,
    position: "relative",
    height: "auto",
  },
  input: {
    fontSize: 16,
    color: Colors.grayDark,
    height: 40,
  },
  inputFocused: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.primary,
  },
  profileImage: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
    borderRadius: 10,
  },
  expandingInput: {
    minHeight: 40,
    maxHeight: 200,
  },
});

export default OfreceServicio;
