// config.js
export const SERVER_ADDRESS = `https://trocalia.net`;
export const SHARE_BASE_URL = `https://trocalia.net`;


// export const SERVER_ADDRESS = "https://trocalia.loca.lt";

// export const SERVER_ADDRESS = "http://192.168.1.138:3000";
// export const SHARE_BASE_URL = "http://192.168.1.138:19006";

// export const SERVER_ADDRESS = "http://192.168.1.152:3000";
// export const SHARE_BASE_URL = "http://192.168.1.45:19006";

// export const SERVER_ADDRESS = "http://localhost:19006";
// export const SHARE_BASE_URL = "http://172.20.10.14:19006";
