import React, { useEffect, useRef, useContext, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Animated,
  Platform,
  Linking,
  Modal,
  Switch,
  Share,
  Alert,
  TouchableWithoutFeedback,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "../styles/styles";
import { AppContext } from "../../AppContext";
import * as SecureStore from "expo-secure-store";
import ProColab from "./Pro";
import LanguageSelector from "../selectables/LanguageSelector";
import NotificationSelector from "../selectables/NotificationSelector";
import DeleteAccount from "../selectables/DeleteAccount";
import Options from "./Options";
import ManageSub from "./ManageSub";

const translations = {
  ESP: {
    hello: "¡Hola, ",
    configuracion: "Configuración",
    ayuda: "Ayuda",
    legal: "Legal",
    cerrarSesion: "Salir de la comunidad",
    trocaliaPro: "Trocalia PRO",
    zona: "Modificar zona",
    idioma: "Idioma",
    notis: "Notificaciones",
    misub: "Gestionar subscripción",
    function: "Cómo funciona?",
    video: "Video resumen",
    contact: "Contáctanos",
    anuncia: "Publicidad, Pon la tuya",
    colab: "Hazte colaborador",
    sat: "Informar de una incidencia",
    faqs: "Preguntas frecuentes",
    privacidad: "Política de privacidad",
    condiciones: "Condiciones del servicio",
    trocs: "Acepto Trocs intercambios",
    deleteAccount: "Eliminar cuenta",
    rateApp: "Valora la aplicación",
    share: "Comparte Trocalia",
    shareMessage: "¡Hola! Te recomiendo la comunidad vecinal📍.",
    social: "Redes Sociales",
    socials: "Síguenos en:",
    enhorabuena: "Gracias por tu apoyo",
    proMessage: "¡Ya formas parte de Trocalia PRO!",
    colabMessage: "¡Ya eres Colaborador de Trocalia!",
  },
  CAT: {
    hello: "Hola, ",
    configuracion: "Configuració",
    ayuda: "Ajuda",
    legal: "Legal",
    cerrarSesion: "Sortir de la comunitat",
    trocaliaPro: "Trocalia PRO",
    zona: "Modificar zona",
    idioma: "Idioma",
    notis: "Notificacions",
    misub: "Gestionar subscripció",
    function: "Com funciona?",
    video: "Vídeo resum",
    contact: "Contacta'ns",
    anuncia: "Publicitat, Posa la teva",
    colab: "Fes-te col·laborador",
    sat: "Informar d'una incidència",
    faqs: "Preguntes freqüents",
    privacidad: "Política de privacitat",
    condiciones: "Condicions del servei",
    trocs: "Accepto Trocs intercanvis",
    deleteAccount: "Eliminar compte",
    rateApp: "Valora l'aplicació",
    share: "Comparteix Trocalia",
    shareMessage: "Hola! Et recomano la comunitat veïnal📍.",
    social: "Xarxes Socials",
    socials: "Segueix-nos a:",
    enhorabuena: "Gràcies pel teu recolzament",
    proMessage: "Ja formes part de Trocalia PRO!",
    colabMessage: "Ja ets Col·laborador de Trocalia!",
  },
  ENG: {
    hello: "Hello, ",
    configuracion: "Settings",
    ayuda: "Help",
    legal: "Legal",
    cerrarSesion: "Log Out",
    trocaliaPro: "Trocalia PRO",
    zona: "Change Location",
    idioma: "Language",
    notis: "Notifications",
    misub: "Manage subscription",
    function: "How it works?",
    video: "Summary Video",
    contact: "Contact us",
    anuncia: "Advertising, Place yours",
    colab: "Become a collaborator",
    sat: "Report an Issue",
    faqs: "FAQs",
    privacidad: "Privacy Policy",
    condiciones: "Terms of Service",
    trocs: "I accept Trocs (exchanges)",
    deleteAccount: "Delete account",
    rateApp: "Rate the app",
    share: "Share Trocalia",
    shareMessage: "Hello! I recommend the neighborhood community📍.",
    social: "Social Media",
    socials: "Follow us on:",
    enhorabuena: "Thanks for your support",
    proMessage: "You are now part of Trocalia PRO!",
    colabMessage: "You are now a Trocalia Collaborator!",
  },
};

const ProfileOptions = ({ onVisibilityChange }) => {
  const {
    token,
    language,
    setToken,
    userData,
    eraseUserData,
    serverAddress,
    updateUserDataField,
  } = useContext(AppContext);
  const animatedValue = useRef(new Animated.Value(-280)).current;
  const t = translations[language];
  const [isLanguageModalVisible, setLanguageModalVisible] = useState(false);
  const navigation = useNavigation();
  const [trocsEnabled, setTrocsEnabled] = useState(false);
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
    useState(false);
  const [isNotificationModalVisible, setNotificationModalVisible] =
    useState(false);
  const [OptionsModalVisible, setOptionsModalVisible] = useState(false);
  const [isManageSubModalVisible, setManageSubModalVisible] = useState(false);
  const [isSocialModalVisible, setSocialModalVisible] = useState(false);
  const [proColabModalVisible, setProColabModalVisible] = useState(false);
  const [initialProColabMode, setInitialProColabMode] = useState("pro");

  useEffect(() => {
    // Notificar que el componente está visible (montado)
    onVisibilityChange(true);

    return () => {
      // Notificar que el componente ya no está visible (desmontado)
      onVisibilityChange(false);
    };
  }, []);

  const toggleSocialModal = () => {
    setSocialModalVisible(!isSocialModalVisible);
  };
  const toggleProColabModal = (mode) => {
    setInitialProColabMode(mode);
    setProColabModalVisible(!proColabModalVisible);
  };

  const openStoreLink = () => {
    const url =
      Platform.OS === "ios"
        ? "https://apps.apple.com/es/app/trocalia/id6478570314?action=write-review;"
        : "https://play.google.com/store/apps/details?id=com.trocalia";
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        //console.error("Cannot open URL: " + url);
      }
    });
  };

  const openUbicationModal = () => {
    const routeName = "Perfil";
    navigation.navigate(routeName, { openLocationModal: true });
  };
  const openNotificationModal = () => {
    setNotificationModalVisible(true);
  };

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, []);

  useEffect(() => {
    if (userData && userData.data[0].admetTrocs !== undefined) {
      setTrocsEnabled(userData.data[0].admetTrocs === 1);
    }
  }, [userData]);

  const handleNavigation = (option) => {
    switch (option) {
      case t.contact:
        Linking.openURL("mailto:hola@trocalia.net");
        break;
      case t.anuncia:
        const url = "https://trocalia.net/anunciants";
        Linking.canOpenURL(url).then((supported) => {
          if (supported) {
            Linking.openURL(url);
          } else {
            //console.log("No se puede abrir la URL: " + url);
          }
        });
        break;
      case t.sat:
        Linking.openURL("mailto:suport@trocalia.net");
        break;
      case t.faqs:
        navigation.navigate("Faq");
        break;
      case t.privacidad:
        navigation.navigate("Privacy");
        break;
      case t.condiciones:
        navigation.navigate("Terms");
        break;
      case t.trocaliaPro:
        if (userData?.data[0]?.tipusUsuari === "Premium") {
          Alert.alert(t.enhorabuena, t.proMessage);
        } else {
          toggleProColabModal("pro");
        }
        break;
      case t.colab:
        if (userData?.data[0]?.tipusUsuari === "Colaborador") {
          Alert.alert(t.enhorabuena, t.colabMessage);
        } else if (userData?.data[0]?.tipusUsuari === "Premium") {
          Alert.alert(t.enhorabuena, t.proMessage);
        } else {
          toggleProColabModal("colab");
        }
        break;
      case t.idioma:
        setLanguageModalVisible(true);
        break;
      case t.function:
        navigation.navigate("Faq");
        break;
      case t.zona:
        openUbicationModal();
        break;
      default:
        break;
    }
  };

  const handleLogout = async () => {
    setToken(null);
    eraseUserData();
    if (Platform.OS === "web") {
      localStorage.removeItem("userToken");
    } else {
      await SecureStore.deleteItemAsync("userToken");
    }
    navigation.reset({
      index: 0,
      routes: [{ name: "Login" }],
    });
  };

  const handleTrocsChange = async (newValue) => {
    setTrocsEnabled(newValue);

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "GT",
            admetTrocs: newValue,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error al enviar la solicitud a la API");
      }

      updateUserDataField("admetTrocs", newValue ? 1 : 0);
    } catch (error) {
      //console.error("Hubo un error al enviar la solicitud:", error);
    }
  };

  const handleShare = async () => {
    const shareMessage = `${t.shareMessage} https://trocalia.net/`;
    try {
      const result = await Share.share({
        message: shareMessage,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // Compartido con un tipo de actividad
        } else {
          // Compartido sin especificar actividad
        }
      } else if (result.action === Share.dismissedAction) {
        // Compartir fue descartado
      }
    } catch (error) {
      //console.error("Error al compartir: ", error);
    }
  };

  return (
    <Animated.View
      style={[
        styles.frame,
        { transform: [{ translateX: animatedValue }] },
        Platform.OS === "web" && styles.frameWeb, // Aplicar estilos específicos para web
      ]}
    >
      <ScrollView
        style={[
          styles.scrollView,
          Platform.OS === "web" && styles.scrollViewWeb,
        ]}
        contentContainerStyle={[styles.scrollContent, { flexGrow: 1 }]}
        horizontal={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={Platform.OS === "web"}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled={true} // Importante para gestos anidados
        scrollEnabled={true}
        bounces={true} // Permitir rebotes en iOS
        overScrollMode="always" // Configuración extra para iOS
      >
        <TouchableWithoutFeedback
          onPress={() => Keyboard.dismiss()} // Cierra el teclado en caso de que esté abierto
          accessible={false}
        >
          <View style={styles.contentContainer}>
            <Text style={styles.h1}>{`${t.hello}${
              userData?.data[0]?.nomUsuari || "Usuario"
            }!`}</Text>
            <View style={styles.optionContainerWithIcon}>
              <Text style={styles.optionText}>{t.trocs}</Text>
              <Image
                source={require("../img/icons/troc.png")}
                style={styles.smallIcon}
              />
              <Switch
                trackColor={{ false: "#767577", true: Colors.primary }}
                thumbColor={trocsEnabled ? Colors.secondary : Colors.secondary}
                ios_backgroundColor="Colors.secondary"
                onValueChange={(newValue) => handleTrocsChange(newValue)}
                value={trocsEnabled}
                style={styles.switch}
              />
            </View>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={toggleSocialModal}
            >
              <Text style={styles.optionText}>{t.social}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            {/* Sección de Configuración */}
            <Text style={styles.categoryTitle}>{t.configuracion}</Text>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.anuncia)}
            >
              <Text style={styles.boldText}>{t.anuncia}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.trocaliaPro)}
            >
              <Text style={styles.boldText}>{t.trocaliaPro}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.colab)}
            >
              <Text style={styles.boldText}>{t.colab}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            {/* Sección de Compartir */}
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={handleShare}
            >
              <Text style={styles.boldText}>{t.share}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.optionContainer}
              onPress={openStoreLink}
            >
              <Text style={styles.boldText}>{t.rateApp}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.zona)}
            >
              <Text style={styles.optionText}>{t.zona}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.idioma)}
            >
              <Text style={styles.optionText}>{t.idioma}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            {/* Sección de Notificaciones */}
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={openNotificationModal}
            >
              <Text style={styles.optionText}>{t.notis}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            {/* Sección de Manage Subs */}
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => setManageSubModalVisible(true)}
            >
              <Text style={styles.optionText}>{t.misub}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>

            {/* Sección de Ayuda */}
            <Text style={styles.categoryTitle}>{t.ayuda}</Text>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.contact)}
            >
              <Text style={styles.optionText}>{t.contact}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() =>
                Linking.openURL("https://youtube.com/shorts/EcF_RhF7K-o")
              }
            >
              <Text style={styles.optionText}>{t.video}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.function)}
            >
              <Text style={styles.optionText}>{t.function}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.faqs)}
            >
              <Text style={styles.optionText}>{t.faqs}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.sat)}
            >
              <Text style={styles.optionText}>{t.sat}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => setDeleteAccountModalVisible(true)}
            >
              <Text style={styles.optionText}>{t.deleteAccount}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            {/* Sección Legal */}
            <Text style={styles.categoryTitle}>{t.legal}</Text>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.privacidad)}
            >
              <Text style={styles.optionText}>{t.privacidad}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionContainer}
              onPress={() => handleNavigation(t.condiciones)}
            >
              <Text style={styles.optionText}>{t.condiciones}</Text>
              <Image
                source={require("../img/icons/next.png")}
                style={styles.icon}
              />
            </TouchableOpacity>
            {/* Cerrar sesión */}
            <View style={[styles.optionContainer, styles.logoutContainer]}>
              <TouchableOpacity onPress={handleLogout}>
                <Text style={styles.logoutText}>{t.cerrarSesion}</Text>
              </TouchableOpacity>
            </View>
            {/* Espacio adicional */}
            <View style={{ height: 10 }}></View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      <DeleteAccount
        isVisible={deleteAccountModalVisible}
        onClose={() => setDeleteAccountModalVisible(false)}
      />
      {/* Modal de PRO */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={proColabModalVisible}
        onRequestClose={() => setProColabModalVisible(false)}
      >
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={() => setProColabModalVisible(false)}
          activeOpacity={1}
        >
          <ProColab
            closeModal={() => setProColabModalVisible(false)}
            initialMode={initialProColabMode}
          />
        </TouchableOpacity>
      </Modal>
      <LanguageSelector
        isVisible={isLanguageModalVisible}
        onClose={() => setLanguageModalVisible(false)}
      />
      <NotificationSelector
        isVisible={isNotificationModalVisible}
        onClose={() => setNotificationModalVisible(false)}
      />
      {/* Modal para Options */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={OptionsModalVisible}
        onRequestClose={() => setOptionsModalVisible(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Options closeModal={() => setOptionsModalVisible(false)} />
          </View>
        </View>
      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isSocialModalVisible}
        onRequestClose={toggleSocialModal}
      >
        <TouchableOpacity
          style={styles.centeredView}
          onPress={toggleSocialModal}
          activeOpacity={1}
        >
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>{t.socials}</Text>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL("https://www.instagram.com/trocalia")
              }
            >
              <Image
                source={require("../img/icons/insta.png")}
                style={styles.socialIcon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  "https://www.facebook.com/people/Trocalia/61557115702157/"
                )
              }
            >
              <Image
                source={require("../img/icons/face.png")}
                style={styles.socialIcon}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL("https://www.linkedin.com/company/trocalia/")
              }
            >
              <Image
                source={require("../img/icons/linkedin.png")}
                style={styles.socialIcon}
              />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </Modal>
      <ManageSub
        isVisible={isManageSubModalVisible}
        onClose={() => setManageSubModalVisible(false)}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  frame: {
    backgroundColor: "#F8F8F8",
    alignItems: "flex-start",
    paddingTop: 50,
    paddingBottom: 0,
    paddingHorizontal: 30,
    width: 280,
    overflow: "hidden", // Removido en web
  },
  frameWeb: {
    // maxHeight: "100vh", // Altura relativa para permitir el scroll
    overflow: "auto", // Permitir scroll en web
    width: 280, // Mantener el ancho fijo
  },
  scrollView: {
    // No agregar flex: 1 para evitar problemas en iOS
  },
  scrollViewWeb: {
    // Puedes ajustar la altura máxima si es necesario
    maxHeight: "95vh",
  },
  scrollContent: {
    flexGrow: 1, // Permite que el contenido crezca y habilite el scroll
  },
  contentContainer: {
    width: "100%",
    alignItems: "flex-start",
    paddingBottom: 0,
    marginTop: 20,
  },
  h1: {
    ...Fonts.poppinsSemiBold24,
    color: Colors.black,
    marginBottom: 20,
  },
  optionContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
    paddingRight: 10,
  },
  categoryTitle: {
    ...Fonts.poppinsMedium18,
    marginBottom: 10,
  },
  logoutText: {
    color: "#FF0000",
    ...Fonts.poppinsRegular16,
    marginTop: 10,
    marginBottom: 50,
  },
  logoutContainer: {
    marginTop: 10,
    marginBottom: 50,
  },
  optionText: {
    ...Fonts.poppinsRegular16,
    flex: 1,
    color: Colors.grayDark,
  },
  boldText: {
    ...Fonts.poppinsRegular16,
    flex: 1,
    color: Colors.black,
  },
  icon: {
    width: 20,
    height: 20,
    marginLeft: "auto",
  },
  optionContainerWithIcon: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
    paddingRight: 10,
  },
  smallIcon: {
    width: 20,
    height: 20,
    marginRight: 20,
  },
  switch: {
    // Puedes ajustar el tamaño o margen del switch si es necesario
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%", // Añadido para que el modal tenga un ancho responsivo
    maxWidth: 400, // Añadido para limitar el ancho en pantallas grandes
  },
  modalTitle: {
    ...Fonts.poppinsMedium18,
    marginBottom: 20,
  },
  socialIcon: {
    width: 60,
    height: 60,
    marginBottom: 20,
  },
});

export default ProfileOptions;
