import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from "react";
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  RefreshControl,
  Vibration,
  Image,
  Platform,
  AppState,
  Linking,
  TextInput,
  Modal,
  Share,
  Animated,
  Easing,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import * as StoreReview from "expo-store-review";
import Greeting from "../components/Greeting.js";
import Gamification from "../components/Gamification.js";
import CardSVertical from "../components/CardSVertical.js";
import { Buscar } from "../components/SearchBar.js";
import ServiciosObjetos from "../selectables/ServiciosObjetos.js";
import { AppContext } from "../../AppContext";
import { SocketContext } from "../../SocketContext.js";
import * as Haptic from "expo-haptics";
import { Audio } from "expo-av";
import Publi from "../components/Publi.js";
import moreImage from "../img/icons/add2.png";
import * as SecureStore from "expo-secure-store";
import * as Notifications from "expo-notifications";
import NotificationPlatform from "../components/NotificationPlatfom";
import * as Device from "expo-device";
import SubscriptionOk from "../components/SubscriptionOk.js";
import * as Application from "expo-application";
import CustomModal from "./CustomModal.js";
import ShareButton from "../components/ShareButton.js";
import RegisterButton from "../components/RegisterButton.js";
import Popup from "../components/Popup.js";
import Colab from "../components/Colab.js";
import ProColab from "../components/Pro.js";

const translate = {
  ESP: {
    whatDoYouNeed: "¿Qué necesitas hoy?",
    seeMore: "Ver más",
    oferta: "Subir\noferta",
    demanda: "Subir\ndemanda",
    updateAvailable: "📲 Actualización disponible",
    updateMessage:
      "Hay una nueva versión disponible. Por favor, actualiza la aplicación.",
    updateRequired: "Actualización requerida",
    updateRequiredMessage:
      "Para usar la aplicación se requiere una versión más nueva. Por favor, actualice la aplicación.",
    updateNow: "Actualizar ahora",
    close: "Cerrar",
    notificationsTitle: "¡No te pierdas nada importante!",
    notificationsMessage:
      "🔔 Activa las notificaciones para recibir mensajes y eventos importantes. ⚡️ ¡Sé el primero en saber las novedades locales!",
    enableNotifications: "Activar notificaciones",
    helpTitle: "Necesitamos tu ayuda",
    helpMessage: "Si puedes apoyar el proyecto, nos iría genial tu donación.",
    donate: "Donar",
    verificationModalTitle: "Un último paso...",
    verificationModalMessage:
      "Ingresa el código de 4 dígitos que hemos enviado a tu correo {email}. Este paso es para garantizar tu seguridad. Si no recibes el código, revisa la ",
    spamMessage: "carpeta no deseados.",
    verificationCodePlaceholder: "Ingresa el código aquí",
    verificationButton: "Verificar Código",
    notReceivedQuery: "¿No has recibido el código?",
    resendCodeButton: "Obtener un nuevo código",
    notokcode: "Código incorrecto. Por favor, inténtalo de nuevo.",
    codesend: "Se ha enviado un correo con un nuevo código",
    restartButton: "Volver a empezar",
    shareTitle: "¿Quieres invitar a alguien? 😊",
    shareMessage:
      "Compartiendo la Red de Economía Vecinal, ayudas a que más personas se unan a la comunidad. 🚀",
    shareButtonText: "Compartir",
    shareCloseButtonText: "Cerrar",
    needAppTitle: "Descarga la App de Trocalia",
    needAppMessage: (os) =>
      `Estás conectado desde un navegador. Para una mejor experiencia, descarga la app desde ${
        os === "iOS" ? "Apple Store" : "Google Play"
      }.`,
    needAppButtonText: "Descargar",
    shareAppMessage:
      "Hola! Te recomiendo la Xarxa d’Economia Vecinal 📍 permite ofrecer y demandar Servicios y Objetos.\n👇\nhttps://trocalia.net/",
  },
  CAT: {
    whatDoYouNeed: "Què necessites avui?",
    seeMore: "Veure'n més",
    oferta: "Pujar\noferta",
    demanda: "Pujar\ndemanda",
    updateAvailable: "Actualització disponible",
    updateMessage:
      "Hi ha una nova versió disponible. Si us plau, actualitza l'aplicació.",
    updateRequired: "📲 Actualització requerida",
    updateRequiredMessage:
      "Per utilitzar l'aplicació es requereix una versió més nova. Si us plau, actualitza l'aplicació.",
    updateNow: "Actualitzar ara",
    close: "Tancar",
    notificationsTitle: "No et perdis res important!",
    notificationsMessage:
      "🔔 Activa les notificacions per rebre missatges i esdeveniments importants. ⚡️ Sigues el primer a conèixer les novetats locals!",
    enableNotifications: "Activar notificacions",
    helpTitle: "Necessitem la teva ajuda",
    helpMessage:
      "Si pots donar suport al projecte, ens aniria genial la teva donació.",
    donate: "Donar",
    verificationModalTitle: "Un últim pas...",
    verificationModalMessage:
      "Introdueix el codi de 4 dígits que hem enviat al teu correu {email}. Aquest pas és per garantir la teva seguretat. Si no reps el codi mira la ",
    spamMessage: "carpeta no desitjats.",
    verificationCodePlaceholder: "Introdueix el codi aquí",
    verificationButton: "Verificar Codi",
    notReceivedQuery: "No has rebut el codi?",
    resendCodeButton: "Obtindre un nou codi",
    notokcode: "Codi incorrecte. Si us plau, intenta-ho de nou.",
    codesend: "S'ha enviat un correu amb un nou codi",
    restartButton: "Tornar a començar",
    shareTitle: "Vols convidar algú? 😊",
    shareMessage: "Compartint la Xarxa d’Economia Veïnal, ajudes a que més persones s’uneixin a la comunitat. 🚀",
    shareButtonText: "Compartir",
    shareCloseButtonText: "Tancar",
    needAppTitle: "Descarrega l'App de Trocalia",
    needAppMessage: (os) =>
      `Estàs connectat des d'un navegador. Per a una millor experiència, descarrega l'App des de ${
        os === "iOS" ? "Apple Store" : "Google Play"
      }.`,
    needAppButtonText: "Descarregar",
    shareAppMessage:
      "Hola! Et recomano la Xarxa d’Economia Veïnal 📍 permet oferir i demandar Serveis i Objectes.\n👇\nhttps://trocalia.net/",
  },
  ENG: {
    whatDoYouNeed: "What do you need today?",
    seeMore: "See more",
    oferta: "Upload\noffer",
    demanda: "Upload\nneed",
    updateAvailable: "📲 Update available",
    updateMessage: "A new version is available. Please update the app.",
    updateRequired: "Update required",
    updateRequiredMessage:
      "To use the app, a newer version is required. Please update the app.",
    updateNow: "Update now",
    close: "Close",
    notificationsTitle: "Don't miss anything important!",
    notificationsMessage:
      "🔔 Enable notifications to receive important messages and events. ⚡️ Be the first to know local news!",
    enableNotifications: "Enable notifications",
    helpTitle: "We need your help",
    helpMessage:
      "If you can support the project, your donation would be greatly appreciated.",
    donate: "Donate",
    verificationModalTitle: "One last step...",
    verificationModalMessage:
      "Enter the 4-digit code we sent to your email {email}. This step is to ensure your security. If you don't receive the code, check your ",
    spamMessage: "Spam folder",
    verificationCodePlaceholder: "Enter the code here",
    verificationButton: "Verify Code",
    notReceivedQuery: "Didn't receive the code?",
    resendCodeButton: "Get a new code",
    notokcode: "Incorrect code. Please try again.",
    codesend: "An email with a new code has been sent",
    restartButton: "Start Over",
    shareTitle: "Will you help us grow?",
    shareMessage:
      "If you know someone who might be interested, share the community.",
    shareButtonText: "Share",
    shareCloseButtonText: "Close",
    needAppTitle: "Enhance your experience",
    needAppMessage: (os) =>
      `You are connected from a browser. For a better experience, download the app from the ${
        os === "iOS" ? "Apple Store" : "Google Play"
      }.`,
    needAppButtonText: "Download",
    shareAppMessage:
      "Hi! I recommend the Local Economy Network 📍 to offer and request Services and Items.\n👇\nhttps://trocalia.net/",
  },
};

const capitalizeFirstLetterOfSentence = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const HomeScreen = (route) => {
  const {
    isTokenLoading,
    token,
    updateUser,
    serverAddress,
    language,
    userData,
    miID,
    setToken,
    updateToken,
    needsRefresh, 
    requestRefresh
  } = useContext(AppContext);
  //console.log("userData", userData);
  const navigation = useNavigation();
  const [panelsTitles, setPanelsTitles] = useState({
    panel1_esp: "",
    panel1_cat: "",
    panel1_ing: "",
    panel2_esp: "",
    panel2_cat: "",
    panel2_ing: "",
    panel3_esp: "",
    panel3_cat: "",
    panel3_ing: "",
    panel4_esp: "",
    panel4_cat: "",
    panel4_ing: "",
    panel5_esp: "",
    panel5_cat: "",
    panel5_ing: "",
    panel6_esp: "",
    panel6_cat: "",
    panel6_ing: "",
  });
  const [panelsData, setPanelsData] = useState({
    panel1: [],
    panel2: [],
    panel3: [],
    panel4: [],
    panel5: [],
    panel6: [],
  });
  const [isPanelsLoaded, setIsPanelsLoaded] = useState({
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
  });
  const panels = ["panel1", "panel2", "panel3", "panel4", "panel5", "panel6"];
  const [offsets, setOffsets] = useState({
    panel1: 0,
    panel2: 0,
    panel3: 0,
    panel4: 0,
    panel5: 0,
    panel6: 0,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const lastUpdateRef = useRef(Date.now());
  const { isSubscriptionOkVisible, setIsSubscriptionOkVisible } =
    useContext(SocketContext);
  const appState = useRef(AppState.currentState);
  const lastBackgroundTime = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [InfoFromServer, setInfoFromServer] = useState(null);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    message: "",
    actions: [],
  });

  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [shareButtonKey, setShareButtonKey] = useState(0);
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState("");
  const [verificationEmail, setVerificationEmail] = useState("");
  const [initialTokenLoad, setInitialTokenLoad] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [modalMode, setModalMode] = useState("pro");
  const [popupType, setPopupType] = useState(null);
  const [isProColabModalVisible, setIsProColabModalVisible] = useState(false);
  const QR_MODAL_LAST_SHOWN_KEY = "qrModalLastShown";
  const EIGHT_HOURS_IN_MS = 8 * 60 * 60 * 1000;
  const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

  const scaleAnim = useRef(new Animated.Value(1)).current; // Escala inicial
  const rotateAnim = useRef(new Animated.Value(0)).current; // Rotación inicial
  const rotateInterpolate = rotateAnim.interpolate({
    inputRange: [-180, 0, 180],
    outputRange: ["-180deg", "0deg", "180deg"], // Rotación de -720 a 720 grados
  });

  const handleCloseQrModal = () => {
    setQrModalVisible(false); // Cerrar modal al hacer clic
  };

  const getLastQrModalShown = () => {
    try {
      const lastShown = localStorage.getItem(QR_MODAL_LAST_SHOWN_KEY);
      return lastShown ? parseInt(lastShown, 10) : null;
    } catch (error) {
      //console.error("Error al obtener el timestamp del modal QR:", error);
      return null;
    }
  };

  const setLastQrModalShown = (timestamp) => {
    try {
      localStorage.setItem(QR_MODAL_LAST_SHOWN_KEY, timestamp.toString());
    } catch (error) {
      //console.error("Error al establecer el timestamp del modal QR:", error);
    }
  };

  const vibrateWithSound = async () => {
    try {
      if (Platform.OS !== "web") {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
      // const soundObject = new Audio.Sound();
      // await soundObject.loadAsync(require("../img/gota.mp3"));
      // await soundObject.playAsync();
    } catch (error) {
      //console.error("Error al reproducir el sonido: ", error);
    }
  };

  const resetPanelData = () => {
    setPanelsData({
      panel1: [],
      panel2: [],
      panel3: [],
      panel4: [],
      panel5: [],
      panel6: [],
    });
    setOffsets({
      panel1: 0,
      panel2: 0,
      panel3: 0,
      panel4: 0,
      panel5: 0,
      panel6: 0,
    });
  };

  const handleCloseSubscriptionOk = () => {
    setIsSubscriptionOkVisible(false);
  };

  const handlePressPublicitari = () => {
    Linking.openURL("https://trocalia.net/anunciants/");
  };

  const handlePressAction = () => {
    const hasOfertes =
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].numOfertes !== null &&
      userData.data[0].numOfertes !== "0";

    if (hasOfertes) {
      // Si tiene ofertas, abrir el modal de Pro
      toggleProModal();
    } else {
      // Si no tiene ofertas, abrir el modal de Colab
      toggleColabModal();
    }
  };

  const getTitleForPanel = useCallback(
    (panelName, currentLanguage) => {
      let titleKey;
      if (currentLanguage === "ENG") {
        titleKey = `${panelName}_ing`;
      } else {
        titleKey = `${panelName}_${currentLanguage.toLowerCase()}`;
      }
      const titleText =
        panelsTitles[titleKey] ||
        capitalizeFirstLetterOfSentence(panelName.replace("_", " "));

      if (
        (panelName === "panel4" ||
          panelName === "panel5" ||
          panelName === "panel1" ||
          panelName === "panel2") &&
        userData &&
        userData.data &&
        userData.data[0] &&
        userData.data[0].Id_zona
      ) {
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={styles.titleText}>{titleText + " "}</Text>
            <Text style={{ ...Fonts.poppinsMedium18, color: Colors.primary }}>
              {userData.data[0].Id_zona}
            </Text>
          </View>
        );
      } else {
        return <Text style={styles.titleText}>{titleText}</Text>;
      }
    },
    [panelsTitles, userData]
  );
  const handleLogout = async () => {
    setToken(null);
    if (Platform.OS === "web") {
      localStorage.removeItem("userToken");
    } else {
      await SecureStore.deleteItemAsync("userToken");
    }
    navigation.reset({
      index: 0,
      routes: [{ name: "Register" }],
    });
  };

  const fetchUserProfile = useCallback(async () => {
    if (!token) {
      return;
    }
    try {
      const profileResponse = await fetch(`${serverAddress}/api/v1/perfils`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const profileData = await profileResponse.json();

      // console.log("Respuesta del servidor:", profileData);

      if (profileResponse.status === 200 && profileData.data.status !== "NOK") {
        updateUser(profileData);
      } else if (
        profileData.data.status === "NOK" &&
        profileData.data.error === "NO_BD"
      ) {
        //console.log("Token correcto pero no se encuentra en la base de datos. Cerrando sesión.");
        handleLogout();
      } else {
        //console.log(profileData.data.error || "Error al obtener el perfil");
        updateUser(null); // Limpiar userData si hay error
        return;
      }
    } catch (error) {
      //console.log("Error al obtener el perfil: " + error.message);
      updateUser(null); // Limpiar userData si hay error de red
      return;
    }
  }, [token, serverAddress, updateUser, handleLogout]);

  const fetchingPanelsRef = useRef({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
  });

  const fetchPanelData = useCallback(
    async (panelName, offsetParam) => {
      // Evita fetch duplicado si ya hay un fetch en progreso para este panel
      if (fetchingPanelsRef.current[panelName]) {
        //console.log(`[DEBUG] Fetch ya en progreso para ${panelName}, se cancela este fetch.`);
        return;
      }

      fetchingPanelsRef.current[panelName] = true; // Marca el panel como "en progreso"
      setIsFetching(true);
      try {
        const offset =
          offsetParam !== undefined ? offsetParam : offsets[panelName];
        //console.log(`[DEBUG] Offset para ${panelName}: ${offset}`);

        //console.log(`[DEBUG] Iniciando fetch para ${panelName} con URL: ${serverAddress}/api/v1/perfils/meuspanels/${panelName}?offset=${offset}`);
        const response = await fetch(
          `${serverAddress}/api/v1/perfils/meuspanels/${panelName}?offset=${offset}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          //console.error(`[ERROR] Error al cargar datos del panel ${panelName}: ${response.statusText}`);
          throw new Error("Error al cargar datos del panel");
        }

        const data = await response.json();
        //console.log(`[DEBUG] Datos recibidos para ${panelName}`);

        if (data.status === "OK" && data.data.length > 0) {
          setPanelsTitles((prevTitles) => ({
            ...prevTitles,
            [`${panelName}_esp`]: data.data[0][`${panelName}_esp`],
            [`${panelName}_cat`]: data.data[0][`${panelName}_cat`],
            [`${panelName}_ing`]: data.data[0][`${panelName}_ing`],
          }));

          setPanelsData((prevData) => ({
            ...prevData,
            [panelName]:
              offset === 0
                ? data.data[0][panelName]
                : [...prevData[panelName], ...data.data[0][panelName]],
          }));

          setOffsets((prevOffsets) => ({
            ...prevOffsets,
            [panelName]: offset + data.data[0][panelName].length,
          }));

          if (
            ["panel2", "panel3", "panel4", "panel5", "panel6"].includes(
              panelName
            )
          ) {
            setIsPanelsLoaded((prevLoaded) => ({
              ...prevLoaded,
              [panelName]: true,
            }));
            //console.log(`[DEBUG] Panel ${panelName} marcado como cargado`);
          }
        } else {
          //console.log(`[DEBUG] No se encontraron datos para ${panelName} o el estado no es OK`);
        }
      } catch (error) {
        //console.error(`[ERROR] Error al obtener datos de ${panelName}: ${error.message}`);
      } finally {
        fetchingPanelsRef.current[panelName] = false; // Marca el panel como "no en progreso"
        setIsFetching(false);
        //console.log(`[DEBUG] Finalización de fetch para ${panelName}`);
      }
    },
    [token, serverAddress, offsets, panelsTitles, panelsData]
  );

  const onRefresh = useCallback(async () => {
    vibrateWithSound();
    setRefreshing(true);

    setPanelsTitles({
      panel1_esp: "",
      panel1_cat: "",
      panel1_ing: "",
      panel2_esp: "",
      panel2_cat: "",
      panel2_ing: "",
      panel3_esp: "",
      panel3_cat: "",
      panel3_ing: "",
      panel4_esp: "",
      panel4_cat: "",
      panel4_ing: "",
      panel5_esp: "",
      panel5_cat: "",
      panel5_ing: "",
      panel6_esp: "",
      panel6_cat: "",
      panel6_ing: "",
    });
    resetPanelData();

    await fetchUserProfile();

    // Llamadas a fetchPanelData en el orden en que se muestran los paneles
    await fetchPanelData("panel1", 0);
    await fetchPanelData("panel5", 0);
    await fetchPanelData("panel4", 0);
    await fetchPanelData("panel2", 0);
    await fetchPanelData("panel6", 0);
    await fetchPanelData("panel3", 0);

    setShareButtonKey((prevKey) => prevKey + 1);

    setRefreshing(false);
  }, [fetchUserProfile, fetchPanelData, panels]);

  async function registerForPushNotificationsAsync() {
    if (!Device.isDevice) {
      //console.log("Debe usar un dispositivo físico para las notificaciones push.");
      return;
    }
    if (Platform.OS === "web") {
      //console.log("Las notificaciones push no son compatibles con aplicaciones web.");
      return;
    }

    // Verifica si las notificaciones están activadas
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      //console.log('¡No se pudo obtener el token para las notificaciones push!');
      return;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;

      // Solo envía el token si las notificaciones están activadas y el token no es null
      if ((Platform.OS === "android" || Platform.OS === "ios") && pushToken) {
        await sendPushTokenToServer(pushToken);
      } else {
        //console.log("El dispositivo no es compatible o el pushToken no está disponible.");
      }
    } catch (error) {
      //console.error("Error al obtener o enviar el push token:", error);
    }
  }

  async function sendPushTokenToServer(pushToken) {
    if (!token) {
      //console.log("Token de usuario no disponible, no se enviará el pushToken al servidor.");
      return;
    }

    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (response.ok) {
        //console.log("Push token enviado al servidor correctamente.");
      } else {
        //console.error("Error al enviar el push token al servidor.");
      }
    } catch (error) {
      // console.error("Error al enviar el push token al servidor:", error);
    }
  }

  useEffect(() => {
    const isUserGuest =
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].tipusUsuari === "Guest";

    if (isUserGuest) {
      setPopupType("1"); // Asignar el tipo de Popup como "compartir"
      setPopupVisible(true); // Mostrar el Popup
    }

    if (
      token &&
      !isUserGuest &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      registerForPushNotificationsAsync();
    }
  }, [token, userData]);

  const requestReviewAndNotifyServer = async () => {
    const isAvailable = await StoreReview.isAvailableAsync();
    if (isAvailable) {
      await StoreReview.requestReview();
      await sendActionToServer("haValoratLaApp");
    }
  };

  async function sendDeviceInfoToServer() {
    if (
      !token ||
      (userData &&
        userData.data &&
        userData.data[0] &&
        userData.data[0].tipusUsuari === "Guest")
    ) {
      // No enviar información del dispositivo si el usuario es "Guest"
      return;
    }

    const deviceInfo = {
      deviceType: Device.deviceType,
      osName: Device.osName,
      appVersion: Application.nativeApplicationVersion,
    };

    const url = `${serverAddress}/api/v1/perfils/actuacions`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "deviceInfo",
          deviceInfo,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        //console.log("Información del dispositivo enviada al servidor correctamente");
        // console.log("Respuesta del servidor:", responseData);
        if (responseData.data.newToken) {
          updateToken(responseData.data.newToken); // Llama a updateToken del contexto para actualizar el token
          // console.log("Token actualizado con éxito");
        }

        handleServerResponse(responseData.data);
        setInfoFromServer(responseData.data);
      } else {
        //console.error("Error al enviar la información del dispositivo al servidor");
        //console.error("Respuesta del servidor:", responseData);
      }
    } catch (error) {
      //console.error("Error al enviar la información del dispositivo al servidor:", error);
    }
  }

  const sendActionToServer = async (action) => {
    //console.log(`sendActionToServer invoked with action: ${action}`);
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: action,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        //console.log(data); // Imprimir la respuesta del servidor
        if (data.status === "OK" && data.data.status === "OK") {
          //console.log(`${action} recorded successfully`);
        } else {
          //console.log(`Failed to record ${action}`, data); // Detallar el fallo
        }
      } else {
        //console.log(`Failed to send ${action} to server`, response.statusText);
      }
    } catch (error) {
      //console.error(`Error recording ${action}:`, error);
    }
  };
  // useEffect(() => {
  //   const simulatedPopupType = 1;
  //   if ([1, 2, 3, 4].includes(simulatedPopupType)) {
  //     setPopupType(simulatedPopupType);
  //     setPopupVisible(true);
  //   }
  // }, []);

  const handleServerResponse = async (data) => {
    const { status } = await Notifications.getPermissionsAsync();
    const notificationsEnabled = status === "granted";
    //console.log("data", data);

    // Verificamos si data.popup es un número válido y el tipusUsuari es "Basic"
    if (data.popup && [1, 2, 3, 4].includes(data.popup)) {
      setPopupType(data.popup); // Establece el tipo de popup
      setPopupVisible(true); // Muestra el popup
    } else {
      setPopupVisible(false); // Oculta el popup
    }

    if (data.situacioUsuari === "P" && data.email) {
      setVerificationEmail(data.email);
      setIsVerificationModalVisible(true);
    } else if (
      data.updateRequired &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      setModalData({
        title: translate[language].updateRequired,
        message: translate[language].updateRequiredMessage,
        actions: [
          {
            label: translate[language].updateNow,
            action: handleUpdate,
          },
        ],
      });
      setModalVisible(true);
    } else if (
      data.updateAvailable &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      setModalData({
        title: translate[language].updateAvailable,
        message: translate[language].updateMessage,
        actions: [
          {
            label: translate[language].updateNow,
            action: handleUpdate,
          },
          {
            label: translate[language].close,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
    } else if (
      data.notifications &&
      !notificationsEnabled &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      setModalData({
        title: translate[language].notificationsTitle,
        message: translate[language].notificationsMessage,
        actions: [
          {
            label: translate[language].enableNotifications,
            action: openSettings,
          },
          {
            label: translate[language].close,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
      await sendActionToServer("LiHemDemanatQueActiviLesNotis");
    } else if (data.customMessage) {
      setModalData({
        title: data.customMessage.title[language],
        message: data.customMessage.message[language],
        actions: data.customMessage.actions.map((action) => ({
          label: action.label[language],
          action:
            action.action === "close"
              ? handleCloseModal
              : () => Linking.openURL(action.action),
        })),
      });
      setModalVisible(true);
      await sendActionToServer("LiHemEnviatCustomMessage");
    } else if (data.customMessage2) {
      setModalData({
        title: data.customMessage2.title[language],
        message: data.customMessage2.message[language],
        actions: data.customMessage2.actions.map((action) => ({
          label: action.label[language],
          action:
            action.action === "close"
              ? handleCloseModal
              : () => Linking.openURL(action.action),
        })),
      });
      setModalVisible(true);
      await sendActionToServer("LiHemEnviatCustomMessage2");
    } else if (
      data.rate &&
      (Platform.OS === "ios" || Platform.OS === "android")
    ) {
      setTimeout(async () => {
        if (await StoreReview.isAvailableAsync()) {
          await requestReviewAndNotifyServer();
        }
      }, 40000); // 40 segundos de retraso
      await sendActionToServer("LiHemDemanatQueValoriLaApp");
    } else if (
      data.share &&
      (Platform.OS === "ios" || Platform.OS === "android")
    ) {
      setModalData({
        title: translate[language].shareTitle,
        message: translate[language].shareMessage,
        actions: [
          {
            label: translate[language].shareButtonText,
            action: handleShare,
          },
          {
            label: translate[language].shareCloseButtonText,
            action: handleCloseModal,
          },
        ],
      });
      setModalVisible(true);
      await sendActionToServer("LiHemDemanatQueComparteixiLaApp");
    } else if (data.needapp) {
      let os = detectOS();
      //console.log('Sistema Operativo detectado (detectOS):', os);

      // Forzamos la detección correcta en el entorno de desarrollo de Expo
      if (
        Platform.OS !== "web" &&
        (Device.osName === "iOS" || Device.osName === "Android")
      ) {
        // Si es una app nativa (en Expo) forzamos el OS correcto
        os = Device.osName === "iOS" ? "iOS" : "Android";
        // console.log('Sistema Operativo corregido en entorno nativo:', os);
      }

      // Solo mostrar needapp si estás en la web
      if (Platform.OS === "web") {
        if (os === "desktop") {
          // Verificar si han pasado más de 8 horas desde la última vez que se mostró el modal QR
          const lastShown = getLastQrModalShown();
          const now = Date.now();

          if (!lastShown || now - lastShown > EIGHT_HOURS_IN_MS) {
            setModalData({
              title: "",
              message: "",
              actions: [],
            });
            setQrModalVisible(true);
            setLastQrModalShown(now); // Actualizar el timestamp
            await sendActionToServer("LiHemDemanatUtilitzarLaApp");
          } else {
            // console.log(
            //   "El modal QR ya se ha mostrado en las últimas 8 horas. No se mostrará nuevamente."
            // );
          }
        } else {
          // Aplicar la misma lógica de tiempo para dispositivos móviles en navegadores
          const lastShown = getLastQrModalShown();
          const now = Date.now();

          if (!lastShown || now - lastShown > EIGHT_HOURS_IN_MS) {
            setModalData({
              title: translate[language].needAppTitle,
              message: translate[language].needAppMessage(os),
              actions: [
                {
                  label: translate[language].needAppButtonText,
                  action: openAppOrRedirect,
                },
                {
                  label: translate[language].close,
                  action: handleCloseModal,
                },
              ],
            });
            setModalVisible(true);
            setLastQrModalShown(now); // Actualizar el timestamp
            await sendActionToServer("LiHemDemanatUtilitzarLaApp");
          } else {
            // console.log(
            //   "El mensaje needAppMessage ya se ha mostrado en las últimas 8 horas. No se mostrará nuevamente."
            // );
          }
        }
      }
    }
  };

  const detectOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad/.test(userAgent) && !window.MSStream) {
      return "iPad";
    } else if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else if (/android/i.test(userAgent)) {
      return "Android";
    } else {
      return "desktop";
    }
  };

  const handleShare = async () => {
    const shareMessage = translate[language].shareAppMessage;
    try {
      const result = await Share.share({
        message: shareMessage,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // Compartido con un tipo de actividad
        } else {
          // Compartido sin especificar actividad
        }
        await sendActionToServer("haCompartitLaApp"); // Llamar a la función para enviar el fetch
      } else if (result.action === Share.dismissedAction) {
        // Compartir fue descartado
      }
    } catch (error) {
      //console.error('Error sharing:', error); // Error al compartir
    }
  };

  const openAppOrRedirect = () => {
    const appStoreUrl = "https://apps.apple.com/es/app/trocalia/id6478570314";
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.trocalia";

    if (detectOS() === "iOS") {
      Linking.openURL(appStoreUrl);
    } else if (detectOS() === "Android") {
      Linking.openURL(playStoreUrl);
    }
  };

  const openSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
    }
  };

  const handleUpdate = () => {
    const url =
      Platform.OS === "android"
        ? "https://play.google.com/store/apps/details?id=com.trocalia"
        : "https://apps.apple.com/es/app/trocalia/id6478570314";
    Linking.openURL(url);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  // const toggleProModal = () => {
  //   setProModalVisible(!proModalVisible);
  // };

  // // Función para alternar el modal de Colab
  // const toggleColabModal = () => {
  //   setColabModalVisible(!colabModalVisible);
  // };
  const handlePressPro = () => {
    setModalMode("pro");
    setPopupVisible(false);
    setIsProColabModalVisible(true);
  };

  const handlePressColab = () => {
    setModalMode("colab");
    setPopupVisible(false);
    setIsProColabModalVisible(true);
  };

  // Función para cerrar el Popup
  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleCodeSubmit = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "VC",
            email: verificationEmail,
            codi: verificationCode,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "OK" && data.data === "OK") {
        setIsVerificationModalVisible(false);
      } else {
        setVerificationError(translate[language].notokcode);
      }
    } catch (error) {
      //console.error("Error al verificar el código:", error);
      setVerificationError("Hubo un problema al verificar el código.");
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "CV",
            email: verificationEmail,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "OK" && data.data === "OK") {
        setResendCodeSuccessMessage(translate[language].codesend);
      } else {
        //console.log("Error en el reenvío del código:", data);
      }
    } catch (error) {
      //console.error("Error al reenviar el código:", error);
    }
  };

  // useEffect(() => {
  //   console.log("HomeScreen mounted");

  //   return () => {
  //     console.log("[HomeScreen unmounted");
  //   };
  // }, []);

  useEffect(() => {
    if (token !== null && !initialTokenLoad) {
      // console.log("Realizando refresh debido a cambio de token.");
      onRefresh();
    } else if (token !== null && initialTokenLoad) {
      // Si es la primera carga con un token válido, simplemente marcamos que ya no es la carga inicial
      setInitialTokenLoad(false);
    }
  }, [token]);

  useEffect(() => {
    if (needsRefresh) {
      onRefresh().then(() => {
        requestRefresh(false);
      });
    }
  }, [needsRefresh]);


  useEffect(() => {
    if (
      token &&
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].tipusUsuari !== "Guest"
    ) {
      sendDeviceInfoToServer();
    }
  }, [token, userData]);

  useEffect(() => {
    const isUserGuest =
      userData &&
      userData.data &&
      userData.data[0] &&
      userData.data[0].tipusUsuari === "Guest";

    if (
      token &&
      !isUserGuest &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      registerForPushNotificationsAsync();
    }
  }, [token, userData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!initialDataLoaded && token) {
        await fetchUserProfile();

        // Llamadas a fetchPanelData en el orden en que se muestran los paneles
        await fetchPanelData("panel1", 0);
        await fetchPanelData("panel5", 0);
        await fetchPanelData("panel4", 0);
        await fetchPanelData("panel2", 0);
        await fetchPanelData("panel6", 0);
        await fetchPanelData("panel3", 0);

        setInitialDataLoaded(true);
      }
    };

    fetchData();
  }, [token, initialDataLoaded]);

  useEffect(() => {
    const startPeriodicAnimations = () => {
      // Secuencia de animaciones
      Animated.sequence([
        // 1. Escalar hacia arriba y rotar a la derecha
        Animated.parallel([
          Animated.timing(scaleAnim, {
            toValue: 1.05, // Ampliar ligeramente
            duration: 1500, // 0.5 segundos
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(rotateAnim, {
            toValue: 360, // Dos vueltas a la derecha (720 grados)
            duration: 1500, // 0.5 segundos
            easing: Easing.linear,
            useNativeDriver: true,
          }),
        ]),
        // 2. Escalar hacia abajo y rotar a la izquierda
        Animated.parallel([
          Animated.timing(scaleAnim, {
            toValue: 1, // Volver al tamaño original
            duration: 1500, // 1 segundo
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(rotateAnim, {
            toValue: -360, // Dos vueltas a la izquierda (-720 grados)
            duration: 1500, // 1 segundo
            easing: Easing.linear,
            useNativeDriver: true,
          }),
        ]),
        // 3. Resetear rotación a 0 para evitar acumulación de valores
        Animated.timing(rotateAnim, {
          toValue: 0, // Volver a la posición original
          duration: 0, // Inmediatamente
          useNativeDriver: true,
        }),
      ]).start();
    };

    // Iniciar las animaciones después de 3 segundos
    const initialTimeout = setTimeout(() => {
      startPeriodicAnimations();
      // Configurar un intervalo para repetir cada 10 segundos
      const interval = setInterval(() => {
        startPeriodicAnimations();
      }, 10000); // 10,000 ms = 10 segundos

      // Limpiar el intervalo al desmontar
      return () => clearInterval(interval);
    }, 3000); // 3000 ms = 3 segundos

    // Limpiar animaciones al desmontar
    return () => {
      clearTimeout(initialTimeout);
      scaleAnim.stopAnimation();
      rotateAnim.stopAnimation();
    };
  }, [scaleAnim, rotateAnim]);

  useEffect(() => {
    const now = Date.now();
    if (now - lastUpdateRef.current >= 5000) {
      lastUpdateRef.current = now;
      fetchUserProfile();
    }
  }, [userData, updateUser]);

  useEffect(() => {
    const handleAppStateChange = (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        const now = Date.now();
        if (
          lastBackgroundTime.current &&
          now - lastBackgroundTime.current > FIVE_MINUTES_IN_MS
        ) {
          onRefresh();
        }
      }
      if (nextAppState === "background") {
        lastBackgroundTime.current = Date.now();
      }
      appState.current = nextAppState;
    };

    const subscription = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => {
      subscription.remove();
    };
  }, [onRefresh]);

  

  const navigateToProfileWithAction = () => {
    const routeName = language === "ENG" ? "Profile" : "Perfil";
    let actionType = "";

    if (userData && userData.data && userData.data.length > 0) {
      const hasOfertes =
        userData.data[0].numOfertes !== null &&
        userData.data[0].numOfertes !== "0";
      const hasDemandes =
        userData.data[0].numDemandes !== null &&
        userData.data[0].numDemandes !== "0";

      if (!hasOfertes && !hasDemandes) {
        actionType = "ofrece";
      } else if (hasOfertes && !hasDemandes) {
        actionType = "necesita";
      } else if (!hasDemandes && hasOfertes) {
        actionType = "ofrece";
      }
    }

    navigation.navigate(routeName, {
      selectedOption: actionType,
      openOfferModal: true,
    });
  };

  let fabText;

  const isUserGuest =
    userData &&
    userData.data &&
    userData.data[0] &&
    userData.data[0].tipusUsuari === "Guest";

  if (!isUserGuest && userData && userData.data && userData.data.length > 0) {
    const hasOfertes =
      userData.data[0].numOfertes !== null &&
      userData.data[0].numOfertes !== "0";
    const hasDemandes =
      userData.data[0].numDemandes !== null &&
      userData.data[0].numDemandes !== "0";

    if (!hasOfertes && !hasDemandes) {
      fabText = translate[language].oferta;
    } else if (hasOfertes && !hasDemandes) {
      fabText = translate[language].demanda;
    } else if (!hasOfertes && hasDemandes) {
      fabText = translate[language].oferta;
    }
  }

  const isUserPremium =
    userData &&
    userData.data &&
    userData.data[0] &&
    userData.data[0].tipusUsuari === "Premium";

  const insertAdsInData = (data, panelKey) => {
    const newData = [];
    data.forEach((item, index) => {
      newData.push(
        <CardSVertical
          key={`${panelKey}-item-${index}`}
          data={item}
          showTimeText={panelKey !== "panel6"}
        />
      );

      // Insertar después del segundo elemento (índice 1) y cada 10 elementos
      if (index === 1 || (index > 1 && (index - 1) % 10 === 0)) {
        newData.push(
          <Publi key={`publi-${panelKey}-${index}`} formatAnunci="Q" />
        );
      }
    });
    return newData;
  };
  // useEffect(() => {
  //   console.log("popupVisible:", popupVisible, "popupType:", popupType);
  // }, [popupVisible, popupType]);

  return (
    <View style={{ flex: 1 }}>
      {/* Renderizamos el Popup si popupVisible es true */}
      {popupVisible && (
        <Popup
          visible={popupVisible}
          popupType={popupType}
          onClose={handleClosePopup}
          onPressPro={handlePressPro}
          onPressColab={handlePressColab}
          onPressPublicitari={handlePressPublicitari}
          onPressCompartir={handleShare}
          sendActionToServer={sendActionToServer}
        />
      )}
      <NotificationPlatform />
      <ScrollView
        contentContainerStyle={styles.container}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View style={styles.innerContainer}>
          <Greeting />
          <Gamification />
          <View style={styles.centerContainer}>
            <Buscar />
          </View>
          <View style={styles.shareContainer} key={shareButtonKey}>
            {isUserGuest ? (
              <RegisterButton />
            ) : (
              <ShareButton
                ocultarcomptador={InfoFromServer?.nocomptador === true}
              />
            )}
          </View>
          <View style={styles.serviciosObjetosContainer}>
            <ServiciosObjetos />
          </View>
          <View style={styles.cardContainer}>
            {["panel1", "panel5", "panel4", "panel2", "panel6", "panel3"].map(
              (panel, panelIdx) => {
                if (panelsData[panel].length > 0) {
                  const panelTitle = getTitleForPanel(panel, language);
                  const dataWithAds = insertAdsInData(panelsData[panel], panel);
                  return (
                    <View key={panelIdx}>
                      <View style={styles.titleContainer}>
                        <Text style={styles.titleText}>{panelTitle}</Text>
                      </View>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        contentContainerStyle={styles.cardScrollContainer}
                        onScroll={({ nativeEvent }) => {
                          if (
                            !isFetching &&
                            nativeEvent.layoutMeasurement.width +
                              nativeEvent.contentOffset.x >=
                              nativeEvent.contentSize.width - 50
                          ) {
                            fetchPanelData(panel);
                          }
                        }}
                        scrollEventThrottle={16}
                      >
                        {dataWithAds}
                      </ScrollView>
                      {/* Inserta la publicación debajo de panel3 */}
                      {panel === "panel3" && isPanelsLoaded.panel3 && (
                        <Publi formatAnunci="H" />
                      )}
                      {panel === "panel5" &&
                        isPanelsLoaded.panel2 &&
                        isPanelsLoaded.panel3 && <Publi formatAnunci="H" />}
                      {panel === "panel2" &&
                        isPanelsLoaded.panel4 &&
                        isPanelsLoaded.panel5 && <Publi formatAnunci="H" />}
                    </View>
                  );
                } else {
                  return null;
                }
              }
            )}
          </View>
        </View>
      </ScrollView>
      <SubscriptionOk
        visible={isSubscriptionOkVisible}
        onClose={handleCloseSubscriptionOk}
      />
      <CustomModal
        visible={modalVisible}
        title={modalData.title}
        message={modalData.message}
        actions={modalData.actions}
      />
      {/* Modal para mostrar el QR */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={qrModalVisible}
        onRequestClose={handleCloseQrModal}
      >
        <TouchableOpacity
          style={styles.qrModalBackground}
          onPress={handleCloseQrModal}
        >
          <View style={styles.qrModalView}>
            <Image source={require("../img/QR.webp")} style={styles.qrImage} />
          </View>
        </TouchableOpacity>
      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isVerificationModalVisible}
        onRequestClose={() => setIsVerificationModalVisible(false)}
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>
              {translate[language].verificationModalTitle}
            </Text>
            <Text style={styles.modalMessage}>
              {translate[language].verificationModalMessage.replace(
                "{email}",
                verificationEmail
              )}
              <Text style={styles.boldText}>
                {translate[language].spamMessage}
              </Text>
            </Text>
            <TextInput
              style={styles.inputCodeBox}
              value={verificationCode}
              onChangeText={setVerificationCode}
              placeholder={translate[language].verificationCodePlaceholder}
              keyboardType="numeric"
              maxLength={4}
            />
            {verificationError ? (
              <Text
                style={{
                  color: "red",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                {verificationError}
              </Text>
            ) : null}
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={handleCodeSubmit}
              >
                <Text style={styles.buttonText}>
                  {translate[language].verificationButton}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.resendButton}
                onPress={handleResendCode}
              >
                <Text style={styles.resendButtonText}>
                  {translate[language].notReceivedQuery}
                </Text>
                <Text style={styles.resendButtonTextBold}>
                  {translate[language].resendCodeButton}
                </Text>
              </TouchableOpacity>
              {resendCodeSuccessMessage ? (
                <Text
                  style={{
                    color: "green",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  {resendCodeSuccessMessage}
                </Text>
              ) : null}
              <TouchableOpacity
                style={styles.restartButton}
                onPress={handleLogout}
              >
                <Text style={styles.restartButtonText}>
                  {translate[language].restartButton}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      {fabText && (
        <Animated.View
          style={{
            transform: [{ scale: scaleAnim }],
          }}
        >
          <TouchableOpacity
            style={styles.fab}
            onPress={navigateToProfileWithAction}
          >
            <Animated.Image
              source={moreImage}
              style={[
                styles.fabIcon,
                {
                  transform: [{ rotate: rotateInterpolate }],
                },
              ]}
            />
            <Animated.Text style={styles.fabText}>{fabText}</Animated.Text>
          </TouchableOpacity>
        </Animated.View>
      )}

      <View>
        {/* Modal Pro */}
        <Modal
          visible={isProColabModalVisible}
          animationType="slide"
          onRequestClose={() => setIsProColabModalVisible(false)}
        >
          <ProColab
            closeModal={() => setIsProColabModalVisible(false)}
            initialMode={modalMode}
          />
        </Modal>
      </View>
    </View>
  );
};

const { width } = Dimensions.get("window");
const searchBarWidth = 340;

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === "android" ? 25 : 54,
    paddingBottom: 75,
    backgroundColor: Colors.white,
    alignItems: "center",
    flexGrow: 1,
  },
  innerContainer: {
    width: "100%",
  },
  centerContainer: {
    width: searchBarWidth,
    alignSelf: "center",
    marginTop: 20,
  },
  cardContainer: {
    marginTop: 24,
  },
  cardScrollContainer: {
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 15,
  },
  questionText: {
    ...Fonts.poppinsRegular16,
    color: Colors.black,
    marginTop: 6,
    marginBottom: -18,
    textAlign: "center",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 26,
    marginBottom: 0,
    marginRight: 10,
    marginLeft: 10,
  },
  titleText: {
    ...Fonts.poppinsMedium18,
    color: Colors.grayDark,
  },
  subTitleText: {
    ...Fonts.poppinsRegular14,
    color: Colors.grayDark,
  },
  serviciosObjetosContainer: {
    marginTop: -8,
    marginBottom: -22,
  },
  shareContainer: {
    position: "absolute",
    top: 0,
    right: 10,
    zIndex: 1000, // Asegúrate de que el botón esté por encima de otros elementos
  },
  fab: {
    position: "absolute",
    minWidth: 56,
    height: 56,
    alignItems: "center",
    justifyContent: "center",
    right: 20,
    bottom: 20,
    backgroundColor: Colors.primary,
    borderRadius: 28,
    elevation: 8,
    shadowColor: "#000",
    shadowRadius: 2,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    flexDirection: "row",
    paddingHorizontal: 12, // Ajusta el padding horizontal si es necesario
  },
  fabIcon: {
    width: 24,
    height: 24,
    marginRight: 5,
    // Puedes ajustar más estilos si es necesario
  },
  fabText: {
    color: "#fff",
    ...Fonts.poppinsRegular14,
  },
  modalBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "85%",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  boldText: {
    fontWeight: "bold",
  },
  inputCodeBox: {
    width: 200,
    height: 40,
    textAlign: "center",
    marginVertical: 20,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  buttonContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  button: {
    backgroundColor: Colors.primary,
    borderRadius: 20,
    paddingVertical: 15,
    paddingHorizontal: 30,
    elevation: 2,
    width: "85%",
    marginBottom: 16,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
  resendButton: {
    backgroundColor: "#f8d7da",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginTop: 10,
    width: "75%",
    alignItems: "center",
    marginBottom: 14,
  },
  resendButtonText: {
    color: "#721c24",
    textAlign: "center",
  },
  resendButtonTextBold: {
    color: "#721c24",
    textAlign: "center",
    fontWeight: "bold",
  },
  restartButton: {
    borderColor: Colors.primary,
    borderWidth: 1,
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 10,
    backgroundColor: "white",
  },
  restartButtonText: {
    color: Colors.primary,
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
  },
  // titleText: {
  //   ...Fonts.poppinsMedium18,
  //   color: Colors.grayDark,
  // },
  // subTitleText: {
  //   ...Fonts.poppinsRegular14,
  //   color: Colors.grayDark,
  // },
  qrModalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    justifyContent: "center",
    alignItems: "center",
  },
  qrModalView: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
  },
  qrImage: {
    width: Dimensions.get("window").width * 0.8, // 80% del ancho de la pantalla
    height: Dimensions.get("window").height * 0.8, // 80% de la altura de la pantalla
    resizeMode: "contain", // Asegura que la imagen se ajuste dentro de las dimensiones sin recortarse
  },
});

export default HomeScreen;
