import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Platform,
  Dimensions,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import * as SecureStore from "expo-secure-store";
import Svg, { Path } from "react-native-svg";

import { AppContext } from "../../AppContext";
import trocaliaIcon from "../img/icons/trocalia.png";
import { Colors, Fonts } from "../styles/styles";
import Explain from "../components/Explain";
import NeedPostal from "../selectables/NeedPostal";

export default function Login() {
  const { width } = Dimensions.get("window");
  const svgWidth = width;
  const svgHeight = 180;

  const navigation = useNavigation();

  // Contexto de la aplicación
  const {
    setToken,
    updateUser,
    updateOtherData,
    serverAddress,
    language,
    setHasToken,
    pendingProfileId,
    setPendingProfileId,
  } = useContext(AppContext);

  // Estados del componente
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailTimer, setEmailTimer] = useState(null);
  const [passwordTimer, setPasswordTimer] = useState(null);
  const [isNeedPostalVisible, setIsNeedPostalVisible] = useState(false);
  const [permitirGuest, setPermitirGuest] = useState(true);

  const showNeedPostalModal = () => {
    setIsNeedPostalVisible(true);
  };

  const hideNeedPostalModal = () => {
    setIsNeedPostalVisible(false);
  };

  const translate = {
    CAT: {
      emailPlaceholder: "elteuemail@gmail.com",
      emailValidation: "Si us plau, introdueixi un correu electrònic vàlid.",
      passwordPlaceholder: "Escriu la teva contrasenya",
      passwordValidation:
        "La contrasenya ha de contenir almenys una majúscula i com a mínim 6 caràcters.",
      loginButtonText: "Inicia sessió",
      registerTextPart1: "Ets nou? ",
      registerTextPart2: "Crea un compte",
      userGuestPart1: "Vols fer un cop d'ull?",
      userGuestPart2: "Entrar com a convidat",
      forgotPasswordText: "Has oblidat la contrasenya?",
      termsAndConditions:
        "En continuar, accepteu automàticament les nostres Condicions i Política de privadesa.",
      emailNotRegistered:
        "Aquest correu electrònic no està registrat. Vols crear un compte nou?",
      LoginError: "Email o contrasenya incorrectes, provi de nou.",
      tryAgain: "Tornar a intentar",
    },
    ESP: {
      emailPlaceholder: "tuemail@gmail.com",
      emailValidation: "Por favor ingrese un correo electrónico válido.",
      passwordPlaceholder: "Escribe tu contraseña",
      passwordValidation:
        "La contraseña debe contener al menos una mayúscula y al menos 6 caracteres.",
      loginButtonText: "Iniciar sesión",
      registerTextPart1: "¿Eres nuevo? ",
      registerTextPart2: "Crea una cuenta",
      userGuestPart1: "¿Quieres echar un vistazo?",
      userGuestPart2: "Entrar como invitado",
      forgotPasswordText: "¿Olvidaste la contraseña?",
      termsAndConditions:
        "Al continuar, aceptas automáticamente nuestras Condiciones y Política de privacidad.",
      emailNotRegistered:
        "Este correo electrónico no está registrado. ¿Quieres crear una cuenta nueva?",
      LoginError: "Email o contraseña incorrectos, pruebe de nuevo.",
      tryAgain: "Intentar de nuevo",
    },
    ENG: {
      emailPlaceholder: "youremail@gmail.com",
      emailValidation: "Please enter a valid email address.",
      passwordPlaceholder: "Enter your password",
      passwordValidation:
        "Password must contain at least one uppercase letter and at least 6 characters.",
      loginButtonText: "Log in",
      registerTextPart1: "New here? ",
      registerTextPart2: "Create an account",
      userGuestPart1: "Want to take a look?",
      userGuestPart2: "Enter as guest",
      forgotPasswordText: "Forgot password?",
      termsAndConditions:
        "By continuing, you automatically accept our Terms and Privacy Policy.",
      emailNotRegistered:
        "This email is not registered. Would you like to create a new account?",
      LoginError: "Incorrect email or password, try again.",
      tryAgain: "Try again",
    },
  };

  // Validación del email con debounce
  const validateEmail = (text) => {
    setEmail(text);
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(text)) {
      setEmailError(translate[language].emailValidation);
    } else {
      // El formato del correo electrónico es válido, procede a verificar si está registrado
      checkEmail(text);
    }
  };

  const checkEmail = async (email) => {
    try {
      const response = await fetch(`${serverAddress}/api/v1/auxiliars/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          actuacio: "EM",
          email,
        }),
      });
      const data = await response.json();
      if (data.status === "NOK") {
        Alert.alert(
          translate[language].emailNotRegistered,
          "",
          [
            { text: translate[language].tryAgain, style: "cancel" },
            {
              text: translate[language].registerTextPart2,
              onPress: () => navigation.navigate("Register"),
            },
          ],
          { cancelable: false }
        );
        setEmailError(
          translate[language].emailNotRegistered || "Email no registrado"
        );
      } else {
        setEmailError("");
      }
    } catch (error) {
      //console.error("Error checking email:", error);
      setEmailError(
        translate[language].emailCheckError ||
          "Error al verificar el correo electrónico"
      );
    }
  };

  // Validación de la contraseña con debounce
  const validatePassword = (text) => {
    setPassword(text);
    if (passwordTimer) clearTimeout(passwordTimer);
    setPasswordTimer(
      setTimeout(() => {
        if (!/[A-Z]/.test(text) || text.length < 6) {
          setPasswordError(translate[language].passwordValidation);
        } else {
          setPasswordError("");
        }
      }, 3000)
    );
  };

  // Manejador de inicio de sesión
  const handleLogin = async () => {
    try {
      const response = await fetch(`${serverAddress}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.respuesta === "NOK") {
        // Mostrar un alerta o mensaje en la interfaz de usuario
        alert(translate[language].LoginError);
      } else if (response.status === 200) {
        try {
          if (Platform.OS === "web") {
            localStorage.setItem("userToken", data.token);
          } else {
            await SecureStore.setItemAsync("userToken", data.token);
          }

          setToken(data.token);
          setHasToken(true);

          // Redirecciona al perfil pendiente si existe, de lo contrario a Home
          if (pendingProfileId) {
            navigation.navigate("ProfileOther", { userId: pendingProfileId });
            setPendingProfileId(null);
          } else {
            navigation.navigate("Home");
          }
        } catch (error) {
          alert("Error al almacenar el token: " + error.message);
        }
      } else {
        alert(data.error || "Credenciales inválidas");
      }
    } catch (error) {
      alert(
        translate[language].LoginError
        // error.message
      );
    }
  };

  useEffect(() => {
    const fetchPermitirGuest = async () => {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/auxiliars/permitirGuest`
        );
        const data = await response.json();

        // Verifica que la respuesta sea válida y actualiza el estado
        if (
          data.status === "OK" &&
          Array.isArray(data.data) &&
          data.data.length > 0 &&
          typeof data.data[0].permitirGuest === "number"
        ) {
          // Actualiza el estado basándote en el valor de permitirGuest
          setPermitirGuest(data.data[0].permitirGuest === 1);
        }
      } catch (error) {
        // console.error("Error al llamar a la API permitirGuest:", error);
      }
    };

    fetchPermitirGuest();
  }, [serverAddress]);

  // Función para manejar el acceso como invitado
  const handleGuestLogin = async (postalCode) => {
    const body = JSON.stringify({
      email: "guest@trocalia.net",
      zona: postalCode,
    });

    try {
      const response = await fetch(`${serverAddress}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();
      if (response.ok && data.tokenGuest) {
        // Almacenar el token y manejar la navegación
        if (Platform.OS === "web") {
          localStorage.setItem("userToken", data.tokenGuest);
        } else {
          await SecureStore.setItemAsync("userToken", data.tokenGuest);
        }
        setToken(data.tokenGuest);
        hideNeedPostalModal();
        navigation.navigate("Home");
      } else {
        Alert.alert("Error", "No se pudo iniciar sesión como invitado.");
      }
    } catch (error) {
      //console.error("Error durante la autenticación de invitado", error);
    }
  };

  // Manejador para navegar a la pantalla de registro
  const handleRegisterClick = () => {
    navigation.navigate("Register");
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.blueHeader}>
        <Svg height={svgHeight} width={svgWidth}>
          <Path
            d={`M0,0 L${svgWidth},0 L${svgWidth},160 C${svgWidth},160 ${
              svgWidth / 2
            },200 0,160 Z`}
            fill={Colors.primary}
          />
        </Svg>
      </View>

      <View style={styles.loginFormWrapper}>
        <View style={styles.trocaliaContainer}>
          <Image
            style={styles.trocaliaLogo}
            resizeMode="cover"
            source={trocaliaIcon}
          />
        </View>

        <View style={styles.emailInputWrapper}>
          <TextInput
            style={[styles.emailInputBox, styles.inputBorder]}
            value={email}
            onChangeText={setEmail}
            onBlur={() => validateEmail(email)} // Verificar el correo electrónico al perder el foco
            placeholder={translate[language].emailPlaceholder}
            autoCapitalize="none"
          />
          {emailError ? (
            <Text style={{ color: "red" }}>{emailError}</Text>
          ) : null}
          <Text style={[styles.emailLabel, styles.labelText]}>Email</Text>
        </View>

        <View style={styles.passwordInputWrapper}>
          <TextInput
            style={[styles.passwordInputBox, styles.standardLayout]}
            value={password}
            onChangeText={validatePassword} // Validación aquí
            placeholder={translate[language].passwordPlaceholder}
            secureTextEntry={!showPassword}
          />
          <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
            <Image
              style={styles.visibilityIcon}
              resizeMode="cover"
              source={require("../img/icons/eye.png")}
            />
          </TouchableOpacity>
          {passwordError ? (
            <Text style={{ color: "red" }}>
              {translate[language].passwordValidation}
            </Text>
          ) : null}
          <Text style={[styles.passwordLabel, styles.labelText]}>Password</Text>
        </View>

        <View style={styles.loginBtnWrapper}>
          <TouchableOpacity
            style={[styles.loginBtnBox, styles.standardLayout]}
            onPress={handleLogin}
          >
            <Text style={[styles.loginBtnText, styles.btnText]}>
              {translate[language].loginButtonText}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.bottomSection}>
        <TouchableOpacity onPress={handleRegisterClick}>
          <Text style={styles.standardText}>
            <Text style={styles.grayText}>
              {translate[language].registerTextPart1}
            </Text>
            <Text style={styles.registerText}>
              {translate[language].registerTextPart2}
            </Text>
          </Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate("Forgot")}>
          <Text
            style={[
              styles.forgotPasswordText,
              styles.standardText,
              styles.newLine,
            ]}
          >
            {translate[language].forgotPasswordText}
          </Text>
        </TouchableOpacity>

        {/* Añadir el acceso como invitado aquí */}
        {permitirGuest && (
          <View style={styles.guestAccessContainer}>
            <TouchableOpacity onPress={() => handleGuestLogin("08030")}>
              <Text style={styles.userGuestPart1Style}>
                {translate[language].userGuestPart1}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setIsNeedPostalVisible(true)}>
              <Text style={styles.userGuestPart2Style}>
                {translate[language].userGuestPart2}
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <NeedPostal
        isVisible={isNeedPostalVisible}
        onClose={hideNeedPostalModal}
        onUsePostalCode={handleGuestLogin}
        onUseRandomPostalCode={() => handleGuestLogin("08030")} // Asumiendo "08030" como tu código aleatorio
      />
      <Explain />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  blueHeader: {
    width: "100%",
    height: 140,
    backgroundColor: Colors.primary,
    zIndex: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    alignItems: "center",
  },
  trocaliaContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  trocaliaLogo: {
    width: 240,
    height: 50,
    top: -95,
  },
  loginFormWrapper: {
    width: 315,
    marginTop: 0,
    alignItems: "center",
  },
  emailInputWrapper: {
    marginTop: 20,
  },
  emailInputBox: {
    width: 312,
    height: 49,
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    color: Colors.grayDark,
  },
  emailLabel: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: -25 }],
  },
  passwordInputWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
  passwordInputBox: {
    width: 312,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    color: Colors.grayDark,
  },
  visibilityIcon: {
    width: 18,
    height: 18,
    position: "absolute",
    top: -33,
    right: 10,
  },
  passwordLabel: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: -39 }],
  },
  loginBtnWrapper: {
    marginTop: 20,
    marginBottom: 12,
  },
  loginBtnBox: {
    borderRadius: 5,
    backgroundColor: Colors.primary,
    width: 312,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  loginBtnText: {
    color: "rgba(255, 255, 255, 0.9)",
    fontFamily: Fonts.poppinsSemiBold16.fontFamily,
    fontSize: 16,
  },
  bottomSection: {
    // flexDirection: "row",
    marginTop: 20,
    marginBottom: 20,
    alignItems: "center",
  },
  rememberText: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
    fontSize: 15,
  },
  forgotPasswordText: {
    marginLeft: 10,
    fontWeight: "500",
    fontFamily: "Poppins-Medium",
    color: "#e86969",
  },
  termsAndConditions: {
    marginTop: 20,
    color: Colors.inactive,
    width: 338,
    fontFamily: "Poppins-Regular",
    fontSize: 16,
    textAlign: "center",
  },
  flexCenter: {
    alignItems: "center",
  },
  standardText: {
    ...Fonts.poppinsRegular16,
    fontSize: 16,
  },
  standardLayout: {
    width: 312,
    height: 48,
  },
  inputBorder: {
    borderWidth: 1,
    borderStyle: "solid",
  },
  labelText: {
    ...Fonts.poppinsRegular14,
    height: 21,
    textAlign: "center",
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: 65 }],
  },
  grayText: {
    color: Colors.grayDark,
    fontSize: 16,
  },
  registerText: {
    color: Colors.primary,
    textDecorationLine: "underline",
    fontSize: 16,
  },
  forgotPasswordText: {
    marginLeft: 10,
    fontWeight: "500",
    fontFamily: "Poppins-Medium",
    color: "#e86969",
    marginTop: 15,
  },
  newLine: {
    marginTop: 20,
  },
  userGuestTextContainer: {
    marginTop: 20,
  },
  guestAccessContainer: {
    marginTop: 10, // Ajusta según necesites para más espacio
    alignItems: "center",
  },
  userGuestPart1Style: {
    color: Colors.grayDark, // Igual al estilo de registerTextPart1
    fontSize: 16, // Asegúrate de que coincide con el tamaño de fuente deseado
    marginTop: 15, // Espacio adicional arriba si es necesario
  },
  userGuestPart2Style: {
    color: "green", // Color verde para este texto
    fontSize: 16, // Asegúrate de que coincide con el tamaño de fuente deseado
    textDecorationLine: "underline", // Si deseas subrayarlo
    marginTop: 5, // Espacio entre userGuestPart1 y userGuestPart2
  },
});
