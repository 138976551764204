import React, { useState, useContext, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext";
import { DataContext } from "../../DataContext";
import imageMap from "../db/imageMap";

const translate = {
  ESP: {
    servicios: "Servicios",
    objetos: "Objetos",
  },
  CAT: {
    servicios: "Serveis",
    objetos: "Objectes",
  },
  ENG: {
    servicios: "Services",
    objetos: "Objects",
  },
};

const Carrousel = ({
  setSelectedCategoryIdProp,
  setSelectedSubCategoryIdProp,
  setSelectedOptionProp,
  resetSelection, // Nueva prop
  onResetComplete, // Callback para indicar que el reset está completo
}) => {
  // Cambios de estado para almacenar IDs
  const [selectedOption, setSelectedOption] = useState("servicios");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedObjectId, setSelectedObjectId] = useState(null); // Nueva variable para ID de objetos
  const { language } = useContext(AppContext);
  const { data } = useContext(DataContext);
  const isWeb = Platform.OS === "web";

  useEffect(() => {
    if (resetSelection) {
      // Reset de selección
      setSelectedCategoryId(null);
      setSelectedSubCategoryId(null);
      setSelectedObjectId(null);
      setSelectedCategoryIdProp(null);
      setSelectedSubCategoryIdProp(null);
      onResetComplete(); // Notificar al padre que el reset ha sido completado
    }
  }, [resetSelection]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSelectedOptionProp(option);
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    if (!isWeb) {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    setSelectedOptionProp(option);
  };

  const handleCategoryPress = (categoria) => {
    if (selectedOption === "servicios") {
      setSelectedCategoryId(categoria.id_cat);
      setSelectedCategoryIdProp(categoria.id_cat);
      setSelectedObjectId(null); // Reset para objetos
    } else if (selectedOption === "objetos") {
      setSelectedObjectId(categoria.id_subcat);
      setSelectedCategoryIdProp(categoria.id_subcat);
      setSelectedCategoryId(null); // Reset para servicios
    }
    setSelectedSubCategoryId(null);
  };

  const handleSubCategoryPress = (subcategoria) => {
    setSelectedSubCategoryId(subcategoria.id_subcat);
    setSelectedSubCategoryIdProp(subcategoria.id_subcat);
  };

  const getCategories = () => {
    return selectedOption === "servicios"
      ? data.tipo.find((t) => t.tipo === "Servicios").categorias
      : data.tipo.find((t) => t.tipo === "Objetos").categorias;
  };

  const getSubcategories = () => {
    if (!selectedCategoryId || selectedOption !== "servicios") return [];
    return (
      data.tipo
        .find((t) => t.tipo === "Servicios")
        .categorias.find((cat) => cat.id_cat === selectedCategoryId)
        ?.subcategorias || []
    );
  };
  const selectedSubCategory = selectedSubCategoryId
    ? data.tipo
        .find((t) => t.tipo === "Servicios")
        .categorias.flatMap((cat) => cat.subcategorias)
        .find((subcat) => subcat.id_subcat === selectedSubCategoryId)
    : null;

  const getCategoryName = (item) => {
    if (selectedOption === "servicios") {
      return item[`nombre_${language}`] || item.nombre;
    } else if (selectedOption === "objetos") {
      // Encontrar el objeto correspondiente y devolver su nombre
      const objetoEncontrado = data.tipo
        .find((t) => t.tipo === "Objetos")
        .categorias.find((cat) => cat.id_subcat === item.id_subcat);

      return objetoEncontrado
        ? objetoEncontrado[`nombre_${language}`] || objetoEncontrado.nombre
        : "No encontrado";
    }
  };

  const renderComponent = () => {
    if (!data) return null;
    const currentCategories = getCategories();

    return (
      <>
        <Text style={styles.subcategoryText}>Selecciona una categoria</Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={{ flexGrow: 1 }}
          contentContainerStyle={styles.contentContainer}
        >
          {currentCategories.map((categoria, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => handleCategoryPress(categoria)}
            >
              <View style={styles.item}>
                <Image
                  source={imageMap[categoria.icono]}
                  style={
                    (selectedOption === "servicios" &&
                      selectedCategoryId === categoria.id_cat) ||
                    (selectedOption === "objetos" &&
                      selectedObjectId === categoria.id_subcat)
                      ? styles.icon
                      : styles.iconInactive
                  }
                  resizeMode="cover"
                />
                <Text style={styles.itemText}>
                  {getCategoryName(categoria)}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
        {selectedCategoryId && selectedOption === "servicios" && (
          <>
            <Text style={styles.subcategoryText}>
              Selecciona la subcategoría:
            </Text>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.contentContainer}
            >
              {getSubcategories().map((subcategoria, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleSubCategoryPress(subcategoria)}
                >
                  <View style={styles.item}>
                    <Image
                      source={imageMap[subcategoria.icono]}
                      style={
                        selectedSubCategoryId === subcategoria.id_subcat
                          ? styles.icon // Estilo para subcategoría seleccionada
                          : styles.iconInactive // Estilo para subcategoría no seleccionada
                      }
                      resizeMode="cover"
                    />
                    <Text style={styles.itemText}>
                      {getCategoryName(subcategoria)}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </>
        )}
      </>
    );
  };

  return (
    <View
      style={[
        styles.container,
        isWeb ? { width: Math.min(Dimensions.get("window").width, 800) } : {},
      ]}
    >
      <View style={styles.selector}>
        {/* Bloque para la opción Objetos */}
        <TouchableOpacity
          onPress={() => handleOptionSelect("objetos")}
          style={
            selectedOption === "objetos"
              ? styles.optionContainerSelected
              : styles.optionContainer
          }
        >
          <Text
            style={
              selectedOption === "objetos"
                ? styles.optionSelected
                : styles.optionInactive
            }
          >
            {translate[language]["objetos"]}
          </Text>
        </TouchableOpacity>

        {/* Bloque para la opción Servicios */}
        <TouchableOpacity
          onPress={() => handleOptionSelect("servicios")}
          style={[
            selectedOption === "servicios"
              ? styles.optionContainerSelected
              : styles.optionContainer,
            styles.marginLeft,
          ]}
        >
          <Text
            style={
              selectedOption === "servicios"
                ? styles.optionSelected
                : styles.optionInactive
            }
          >
            {translate[language]["servicios"]}
          </Text>
        </TouchableOpacity>
      </View>
      {renderComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 250,
    marginTop: 20,
    marginBottom: 0,
  },
  selector: {
    flexDirection: "row",
    marginBottom: 16,
  },
  optionInactive: {
    ...Fonts.poppinsRegular16,
    color: Colors.inactive,
    paddingVertical: 8,
  },
  iconInactive: {
    width: 40,
    height: 40,
    tintColor: "#ccc",
  },
  subcategoryText: {
    ...Fonts.poppinsMedium16,
    color: Colors.black,
    marginVertical: 10,
    marginTop: 14,
  },
  optionSelected: {
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
    paddingVertical: 8,
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: "transparent",
    paddingBottom: 1,
  },
  optionContainerSelected: {
    borderBottomWidth: 2,
    borderColor: Colors.primary,
    paddingBottom: 0,
  },
  contentContainer: {
    paddingVertical: 10,
    paddingHorizontal: 0,
  },
  item: {
    alignItems: "center",
    marginRight: 15,
  },
  icon: {
    width: 40,
    height: 40,
  },
  itemText: {
    fontFamily: Fonts.PoppinsRegular,
    fontSize: 12,
    color: Colors.black,
    marginTop: 12,
    textAlign: "center",
    width: 80,
  },
  marginLeft: {
    marginLeft: 30,
  },
});

export default Carrousel;
