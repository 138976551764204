import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from "react";
import SocketIOClient from "socket.io-client";
import { SERVER_ADDRESS } from "./src/docs/config.js";
import { AppContext } from "./AppContext.js";
import { ToastContext } from "./ToastContext.js";
import { NOTIFICATION_MESSAGES } from "./src/db/NotificationMessages.js";
import { AppState } from "react-native";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [notiSocket, setNotiSocket] = useState(null);
  const [hasUnseenMessageNotifications, setHasUnseenMessageNotifications] = useState(false);
  const [hasUnseenGeneralNotifications, setHasUnseenGeneralNotifications] = useState(false);
  const { miID, token, language, updateToken } = useContext(AppContext);
  const { showMessageNotification } = useContext(ToastContext);
  const [hasUnseenNotifications, setHasUnseenNotifications] = useState(false);
  const [showNotificationDot, setShowNotificationDot] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [omitirProximaHVTP, setOmitirProximaHVTP] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);
  const notificationQueueRef = useRef([]);
  const notificationTimerRef = useRef(null);

  // Crear la referencia mutable para 'language'
  const languageRef = useRef(language);

  // Actualiza languageRef cuando 'language' cambie
  useEffect(() => {
    languageRef.current = language;
  }, [language]);

  const translations = {
    ESP: "Tienes {count} nuevas notificaciones",
    ENG: "You have {count} new notifications",
    CAT: "Tens {count} noves notificacions",
  };

  const handleSelectMessages = () => {
    setShowNotificationDot(false); // Oculta el globo en la NavBar
  };

  const [shouldFetchChats, setShouldFetchChats] = useState(false);
  const [nouToken, setNouToken] = useState(null);
  const [isSubscriptionOkVisible, setIsSubscriptionOkVisible] = useState(false);

  const clearNotifications = () => {
    setHasUnseenMessageNotifications(false);
    setHasUnseenGeneralNotifications(false);
    setShowNotificationDot(false);
  };

  const clearMessageNotificationsDot = () => {
    setHasUnseenMessageNotifications(false);
    setShowNotificationDot(hasUnseenGeneralNotifications); // Asegura que el dot solo se muestre para notificaciones generales si hay alguna
  };

  const handleSubscriptionSuccess = () => {
    setIsSubscriptionOkVisible(true);
  };

  const resetShouldFetchChats = () => {
    setShouldFetchChats(false);
  };

  const fetchChats = useCallback(async () => {
    if (!miID) return;
    try {
      const response = await fetch(
        `${SERVER_ADDRESS}/api/v1/perfils/meusxats`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === "OK") {
        const hasUnreadMessages = data.data.some((chat) =>
          chat.missatges.some(
            (message) =>
              (message.situacioMissatge === "R" || message.situacioMissatge === "E") &&
              message.usuariEmisor !== miID
          )
        );
        setHasUnseenMessageNotifications(hasUnreadMessages);
      }
    } catch (error) {
      //console.error("Error al obtener chats:", error);
    }
  }, [miID, token]);
  

  useEffect(() => {
    if (!miID) {
      return;
    }
    fetchChats();
  }, [miID]);

  const fetchNotifications = useCallback(async () => {
    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${SERVER_ADDRESS}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          credentials: true,
        },
        body: JSON.stringify({ actuacio: "DN" }),
      });

      if (!response.ok) {
        return;
      }

      const jsonResponse = await response.json();
      if (jsonResponse.status === "OK") {
        const filteredNotifications = jsonResponse.data
          .filter((item) => item.message.codi !== "EMCH")
          .map((item) => item.message);

        const hasNotifications = filteredNotifications.length > 0;
        setHasUnseenGeneralNotifications(hasNotifications);
        setNotifications((prevNotifications) =>
          [...prevNotifications, ...filteredNotifications].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
      }
    } catch (error) {
      // Manejo de errores
    }
  }, [token]);
  
  useEffect(() => {
    const subscription = AppState.addEventListener("change", async (nextAppState) => {
      if (nextAppState === "active") {
        await fetchChats();         // Ya existe, actualiza notificaciones de mensajes
        await fetchNotifications(); // Agregado: Actualiza notificaciones generales
      }
    });
  
    return () => {
      subscription.remove();
    };
  }, [fetchChats, fetchNotifications]);
  


  useEffect(() => {
    fetchNotifications(); // Obtener notificaciones pendientes al cambiar el token
  }, [token, setHasUnseenGeneralNotifications, setNotifications]);

  useEffect(() => {
    setShowNotificationDot(
      hasUnseenMessageNotifications || hasUnseenGeneralNotifications
    );
  }, [hasUnseenMessageNotifications, hasUnseenGeneralNotifications]);

  useEffect(() => {
    if (!token || notiSocket) {
      return;
    }

    const socket = SocketIOClient(SERVER_ADDRESS, {
      path: "/noti-socket",
      query: { token },
    });

    socket.on("connect", () => {
      socket.emit("join notis", { userId: miID });
      fetchChats();
    });

    socket.on("noti message", (noti) => {
      // Handle subscription success
      if (noti.codi === "novaSubscripcioPro") {
        handleSubscriptionSuccess();
        if (noti.nouToken) {
          updateToken(noti.nouToken);
        }
        return;
      }

      if (noti.codi === "novaSubscripcioColab") {
        handleSubscriptionSuccess();
        if (noti.nouToken) {
          updateToken(noti.nouToken);
        }
        return;
      }

      if (noti.codi === "EMCH") {
        setHasUnseenMessageNotifications(true);
        setShouldFetchChats(true);
        const notificationMessage = NOTIFICATION_MESSAGES[noti.codi]
          ? NOTIFICATION_MESSAGES[noti.codi][languageRef.current]
          : "Notificación desconocida";
        showMessageNotification(notificationMessage);
        return;
      }

      if (noti.codi === "NVAL") {
        setOmitirProximaHVTP(true);
        setTimeout(() => setOmitirProximaHVTP(false), 20000);
      }
      if (omitirProximaHVTP && noti.codi === "HVTP") {
        return;
      }

      setHasUnseenNotifications(true);
      setShowNotificationDot(true);
      setHasUnseenGeneralNotifications(true);

      setNotificationQueue((prevQueue) => {
        const newQueue = [...prevQueue, noti];
        notificationQueueRef.current = newQueue;
        return newQueue;
      });

      setNotifications((prevNotifications) => {
        const updatedNotifications = [...prevNotifications, noti].filter(
          (n) => n.codi !== "EMCH"
        );
        updatedNotifications.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        return updatedNotifications;
      });

      if (!notificationTimerRef.current) {
        notificationTimerRef.current = setTimeout(() => {
          const notificationsToProcess = notificationQueueRef.current;
          setNotificationQueue([]);
          notificationQueueRef.current = [];
          notificationTimerRef.current = null;

          let notificationMessage = "";
          if (notificationsToProcess.length === 1) {
            const noti = notificationsToProcess[0];
            notificationMessage = NOTIFICATION_MESSAGES[noti.codi]
              ? NOTIFICATION_MESSAGES[noti.codi][languageRef.current]
              : "Notificación desconocida";
          } else if (notificationsToProcess.length > 1) {
            const template = translations[languageRef.current] || translations.CAT;
            notificationMessage = template.replace(
              "{count}",
              notificationsToProcess.length
            );
          }

          if (notificationMessage) {
            showMessageNotification(notificationMessage);
          }
        }, 500);
      }
    });

    socket.on("disconnect", () => {
      //console.log("Desconectado del servidor de notificaciones");
    });

    setNotiSocket(socket);

    return () => {
      if (socket) {
        socket.disconnect();
      }
      setNotiSocket(null);
    };
  }, [token]);

  useEffect(() => {
    return () => {
      if (notificationTimerRef.current) {
        clearTimeout(notificationTimerRef.current);
      }
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        notiSocket,
        hasUnseenMessageNotifications,
        setHasUnseenMessageNotifications,
        hasUnseenGeneralNotifications,
        setHasUnseenGeneralNotifications,
        showNotificationDot,
        setShowNotificationDot,
        handleSelectMessages,
        notifications,
        shouldFetchChats,
        resetShouldFetchChats,
        isSubscriptionOkVisible,
        setIsSubscriptionOkVisible,
        nouToken,
        setNouToken,
        clearNotifications,
        clearMessageNotificationsDot,
        fetchNotifications,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
