import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  FlatList,
  Platform,
  Alert,
  StyleSheet,
} from "react-native";
import ProfileInfo from "../components/ProfileInfo";
import SelectorPresentation from "../selectables/SelectorPresentation";
import FirstOffer from "../components/FirstOffer";
import NewOffer from "../components/NewOffer";
import ProfileOptions from "../components/ProfileOptions";
import OfferModal from "../components/OfferModal";
import CardValoracion from "../components/CardValoracion";
import { AppContext } from "../../AppContext";
import NotRating from "../components/NotRating";
import { Colors, Fonts } from "../styles/styles.js";

const moreImage = require("../img/icons/more.png");
const more2Image = require("../img/icons/more2.png");

const translations = {
  ESP: {
    alertTitle: "Oh, vaya...",
    alertMessage:
      "En estos momentos no se pueden cargar los ajustes. Prueba de nuevo más tarde.",
  },
  CAT: {
    alertTitle: "Oh, caram...",
    alertMessage:
      "En aquests moments no es poden carregar els ajustos. Torna-ho a provar més tard.",
  },
  ENG: {
    alertTitle: "Oops...",
    alertMessage: "Settings can't be loaded right now. Please try again later.",
  },
};

const ProfileScreen = ({ route }) => {
  const { userData, token, serverAddress, language } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState("ofrece");
  const [showOptions, setShowOptions] = useState(false);
  const [isProfileOptionsVisible, setIsProfileOptionsVisible] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [isScrollEnabled, setScrollEnabled] = useState(true);
  const { openLocationModal } = route.params || {};
  const [icon, setIcon] = useState(moreImage);
  const [valoraciones, setValoraciones] = useState([]);
  const t = translations[language];

  useEffect(() => {
    if (showOptions) {
      const timer = setTimeout(() => {
        if (!isProfileOptionsVisible) {
          Alert.alert(t.alertTitle, t.alertMessage, [{ text: "OK" }], {
            cancelable: false,
          });
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showOptions, isProfileOptionsVisible]);

  const toggleIcon = () => {
    const newIcon = icon === moreImage ? more2Image : moreImage;
    setIcon(newIcon);
    setShowOptions(!showOptions);
  };

  const handleCloseOptions = () => {
    setShowOptions(false);
    setIcon(moreImage);
  };

  const toggleOfferModal = () => {
    setShowOfferModal(!showOfferModal);
  };

  useEffect(() => {
    if (route.params?.selectedOption) {
      setSelectedOption(route.params.selectedOption);
    }
  }, [route.params]);

  useEffect(() => {
    if (route.params?.openOfferModal) {
      setShowOfferModal(true);
    }
  }, [route.params]);

  useEffect(() => {
    fetchValoraciones();
  }, []);

  const fetchValoraciones = async () => {
    try {
      const url = `${serverAddress}/api/v1/perfils/mhanfetcomentaris`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === "OK" && data.data) {
        setValoraciones(data.data);
      }
    } catch (error) {
      // Manejar error
    }
  };

  return (
    <View style={styles.container}>
      {showOfferModal && (
        <OfferModal type={selectedOption} closeModal={toggleOfferModal} />
      )}
      {/* Usar Modal para ProfileOptions sin animación predeterminada */}
      <Modal
        transparent={true}
        visible={showOptions}
        onRequestClose={handleCloseOptions}
        animationType="none" // Eliminar la animación predeterminada
      >
        <TouchableWithoutFeedback onPress={handleCloseOptions}>
          <View style={styles.modalOverlay}>
            <TouchableWithoutFeedback>
              <View style={styles.modalContent}>
                <ProfileOptions
                  onVisibilityChange={setIsProfileOptionsVisible}
                  closeModal={handleCloseOptions} // Pasamos closeModal a ProfileOptions
                />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <View style={styles.spacer} />
      <View style={styles.profileInfoContainer}>
        <ProfileInfo
          onMoreIconPress={toggleIcon}
          openLocationModalOnInit={openLocationModal}
          icon={icon}
          showOptions={showOptions}
        />
      </View>

      <View style={styles.selectorContainer}>
        <SelectorPresentation
          onSelect={setSelectedOption}
          selectedOption={selectedOption}
        />
      </View>
      <FlatList
        data={[selectedOption]}
        renderItem={({ item }) => (
          <View style={styles.centerContentContainer}>
            {selectedOption === "valoraciones" && (
              <>
                {Platform.OS === "web" ? (
                  <>
                    <CardValoracion valoraciones={valoraciones} />
                    <NotRating />
                  </>
                ) : (
                  <>
                    <NotRating />
                    <CardValoracion valoraciones={valoraciones} />
                  </>
                )}
              </>
            )}
            {item === "ofrece" && (
              <>
                {Platform.OS === "web" ? (
                  <>
                    <NewOffer
                      type="ofrece"
                      setScrollEnabled={setScrollEnabled}
                    />
                    <FirstOffer
                      onPress={toggleOfferModal}
                      selectedOption={selectedOption}
                    />
                  </>
                ) : (
                  <>
                    <FirstOffer
                      onPress={toggleOfferModal}
                      selectedOption={selectedOption}
                    />
                    <NewOffer
                      type="ofrece"
                      setScrollEnabled={setScrollEnabled}
                    />
                  </>
                )}
              </>
            )}
            {item === "necesita" && (
              <>
                {Platform.OS === "web" ? (
                  <>
                    <NewOffer
                      type="necesita"
                      setScrollEnabled={setScrollEnabled}
                    />
                    <FirstOffer
                      onPress={toggleOfferModal}
                      selectedOption={selectedOption}
                    />
                  </>
                ) : (
                  <>
                    <FirstOffer
                      onPress={toggleOfferModal}
                      selectedOption={selectedOption}
                    />
                    <NewOffer
                      type="necesita"
                      setScrollEnabled={setScrollEnabled}
                    />
                  </>
                )}
              </>
            )}
          </View>
        )}
        keyExtractor={(item) => item}
        scrollEnabled={isScrollEnabled}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  spacer: {
    height: 40,
    marginBottom: Platform.select({
      android: 16,
      default: 20,
    }),
  },
  profileInfoContainer: {
    flexDirection: "row",
    marginBottom: -20,
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectorContainer: {
    marginBottom: 10,
    alignSelf: "stretch",
    alignItems: "center",
  },
  centerContentContainer: {
    alignItems: "center",
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  modalContent: {
    width: 280, // Ajusta este valor según el ancho de ProfileOptions
    backgroundColor: "transparent",
  },
  // ... otros estilos existentes
});

export default ProfileScreen;
